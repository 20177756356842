import { useLoaderData, useNavigate, useLocation, Outlet } from 'react-router-dom';

import Modal from '../ui/Modal';

import styles from './ActionUserOptions.module.css';

const ActionUserOptions = () => {
  const user = useLoaderData();
  const navigate = useNavigate();
  const location = useLocation();
  const isConfirm = location.pathname.includes('/confirm');

  const handleEnableUser = () => {
    navigate('confirm/' + (user.pending ? 'enable' : 'disable'));
  }

  const handleBanUser = () => {
    navigate('confirm/' + (user.banned ? 'unban' : 'ban'));
  }

  const handleUpdateRole = () => {
    navigate('confirm/' + (user.role === 'ROLE_GYM' ? 'promotion' : 'gym'))
  }

  return (
    <>
      <Outlet />
      {!isConfirm && (
        <Modal>
          <div className={styles.actions}>
            <h2>{user.organisation}</h2>
            <div className={styles.info}>
              <p className={styles.email}>{user.username}</p>
              <p className={styles.phoneNumber}>{user.phone_number}</p>
              <p className={styles.orgType}>{user.role.includes('ADMIN') ? 'Admin' : user.role.includes('PROMOTION') ? 'Promotion' : 'Gym'}</p>
            </div>
            <button className={styles.enableBtn} onClick={handleEnableUser}>{user.pending ? 'Enable User' : 'Disable User'}</button>
            <button className={styles.banBtn} onClick={handleBanUser}>{user.banned ? 'Unban User' : 'Ban User'}</button>
            <button className={styles.roleBtn} onClick={handleUpdateRole}>{user.role === 'ROLE_GYM' ? 'Set to Promotion' : 'Set to Gym'}</button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ActionUserOptions;