import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { decodeToken, tokenLoader } from '../../util/auth';

import styles from './NewInboxContent.module.css';

const NewInboxContent = ({ users, createNewInbox }) => {
    const [checkedState, setCheckedState] = useState(new Array(users.length).fill(false));
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState(users);
    const [step, setStep] = useState(1);
    const [message, setMessage] = useState('');
    const [selectedNames, setSelectedNames] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const filtered = users.filter(user =>
            user.organisation.toLowerCase().includes(searchTerm.toLowerCase()) ||
            checkedState[users.indexOf(user)]
        );
        setFilteredData(filtered);
    }, [searchTerm, users, checkedState]);

    const handleCheckboxChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);

        const updatedSelectedNames = users
            .filter((_, index) => updatedCheckedState[index])
            .map(user => user.organisation);
        setSelectedNames(updatedSelectedNames);
    };

    const handleContinue = () => {
        setStep(2);
    };

    const handleBack = () => {
        setStep(1);
        setCheckedState(new Array(users.length).fill(false));
        setMessage('');
    };

    const handleCreateChat = async () => {
        const latestToken = await tokenLoader();
        const decodedToken = decodeToken(latestToken);
        const currentUserId = decodedToken.activeRoleUserId;

        const selectedUserIds = filteredData
            .filter((_, index) => checkedState[index])
            .map(user => user.user_id);
        const participants = [...selectedUserIds, currentUserId].filter(id => id);

        const authUrl = process.env.REACT_APP_AUTH_URL;
        try {
            const response = await fetch(`${authUrl}/api/v1/inbox/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${latestToken}`
                },
                body: JSON.stringify({ participants })
            });

            if (!response.ok) {
                throw new Error('Failed to create chat');
            }

            const data = await response.json();
            const inboxId = data.inbox_created;

            if (message) {
                await sendMessage(inboxId, message, latestToken);
            }

            // Refresh inboxes before navigating
            await createNewInbox();

            navigate(`/inbox?selectedInbox=${inboxId}`);
        } catch (error) {
            console.error('Error creating chat:', error);
        }
    };

    const sendMessage = async (inboxId, message, token) => {
        const authUrl = process.env.REACT_APP_AUTH_URL;
        try {
            const response = await fetch(`${authUrl}/api/v1/inbox/send-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ inbox_id: inboxId, message })
            });

            if (!response.ok) {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    const selectedCount = checkedState.filter(Boolean).length;

    return (
        <div className={styles.container}>
            {step === 1 ? (
                <>
                    <h1>Select Users</h1>
                    <div className={styles.search}>
                        <input
                            type="text"
                            placeholder="Search users..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            autoComplete='new-password'
                        />
                    </div>
                    <div className={styles.usersContainer}>
                        {filteredData.map((user) => {
                            const index = users.indexOf(user);
                            return (
                                <div key={user.id} className={styles.userItem}>
                                    <input
                                        type="checkbox"
                                        id={user.user_id}
                                        name="users"
                                        value={user.user_id}
                                        checked={checkedState[index]}
                                        onChange={() => handleCheckboxChange(index)}
                                        className={styles.checkbox}
                                    />
                                    <label htmlFor={user.user_id} className={styles.organisation}>
                                        {user.organisation}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                    <button
                        className={styles.continueButton}
                        onClick={handleContinue}
                        disabled={selectedCount === 0}
                    >
                        Continue
                    </button>
                </>
            ) : (
                <>
                    <div className={styles.header}>
                        <p className={styles.backButton} onClick={handleBack}>
                            {`<< Back`}
                        </p>
                        <h2><strong>Selected {selectedNames.length === 1 ? 'User' : 'Users'}:</strong> {selectedNames.join(', ')}</h2>
                    </div>
                    <textarea
                        className={styles.messageInput}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Leave blank to create an empty chat"
                    />
                    <div className={styles.actionButtons}>
                        {selectedCount > 1 ? (
                            <>
                                <button className={styles.actionButton} onClick={handleCreateChat}>
                                    Create Group Chat
                                </button>
                            </>
                        ) : (
                            <button className={styles.actionButton} onClick={handleCreateChat}>
                                Create Chat
                            </button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default NewInboxContent;