import { decodeToken, checkPromotionLoader } from '../util/auth';

import FighterRequestMatchContent from '../components/dashboard/FighterRequestMatchContent';

const FighterRequestMatch = () => {
  return <FighterRequestMatchContent />
}

export default FighterRequestMatch;

const isFutureEvent = (event) => {
  const eventDate = new Date(event.date_time);
  eventDate.setHours(0, 0, 0, 0); // ignore time

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // ignore time

  return eventDate > currentDate;
}

export const loader = async ({ params }) => {
  const token = await checkPromotionLoader();
  if (token instanceof Response) {
    return token;
  }
  const userId = decodeToken(token).activeRoleUserId;

  const authUrl = process.env.REACT_APP_AUTH_URL;
  const fightersResponse = await fetch(authUrl + '/api/v1/fighters/fetch/' + params.fighterId);
  const fightersResData = await fightersResponse.json();

  const eventsResponse = await fetch(authUrl + '/api/v1/events/promotion/' + userId);
  const eventsResData = await eventsResponse.json();

  const fightsResponse = await fetch(authUrl + '/api/v1/events/fights/' + params.fighterId);
  const fightsResData = await fightsResponse.json();

  const alreadyFightingEvents = fightsResData.map(fight => fight.event_id);

  let sports = [];
  if (fightersResData.mma_stats) sports.push('MMA');
  if (fightersResData.boxing_stats) sports.push('BOXING');
  if (fightersResData.k1_stats) sports.push('K1');
  if (fightersResData.muay_thai_stats) sports.push('MUAY_THAI');
  if (fightersResData.grappling_stats) sports.push('GRAPPLING');

  const upcomingEvents = eventsResData.filter(event => isFutureEvent(event) && !alreadyFightingEvents.includes(event.id));

  return { sports: sports, events: upcomingEvents }
}