import { json, redirect } from 'react-router-dom';
import { checkAuthLoader } from '../util/auth';
import { geocodeAddress } from '../util/maps';

import DirectoryItemForm from '../components/directory/DirectoryItemForm';

const AddDirectoryItem = () => {
  return <DirectoryItemForm />
}

export default AddDirectoryItem;

export const action = async ({ request }) => {
  const formData = await request.formData();
  let data = {};
  for (let [key, value] of formData.entries()) {
    // Check if the key ends with '[]', which denotes it should be an array
    if (key.endsWith('[]')) {
      key = key.slice(0, -2); // Remove the '[]' from the key
      // If the key already exists in the data, push the new value
      if (data[key]) {
        data[key].push(value);
      } else {
        // If the key doesn't exist yet, create an array with the value
        data[key] = [value];
      }
    } else {
      // Convert Yes/No strings to boolean values
      if (value === 'Yes') {
        data[key] = true;
      } else if (value === 'No') {
        data[key] = false;
      } else {
        data[key] = String(value);
      }
    }
  };

  const customFieldA = {
    key: data.customFieldAKey?.trim(),
    value: data.customFieldAValue?.trim()
  };

  const customFieldB = {
    key: data.customFieldBKey?.trim(),
    value: data.customFieldBValue?.trim()
  };

  const extraInfo = [];

  if (customFieldA.key && customFieldA.value) {
    extraInfo.push(customFieldA);
  }

  if (customFieldB.key && customFieldB.value) {
    extraInfo.push(customFieldB);
  }

  const address = data.address;
  let geoLocation = null;

  if (address) {
    const geocode = await geocodeAddress(address);
    geoLocation = geocode[0]?.geometry?.location;
  }

  if (!data.regions) {
    return { message: 'You must select at least one region!', success: false }
  }

  const directoryItemData = {
    type: data.type.trim(),
    name: data.name.trim(),
    regions: data.regions || undefined,
    email: data.email.trim(),
    website: data.website.trim() || undefined,
    phone_number: data.phoneNumber.trim(),
    address: address || undefined,
    coordinates: geoLocation ? {
      latitude: geoLocation.lat(),
      longitude: geoLocation.lng()
    } : undefined,
    extra_info: extraInfo.length > 0 ? extraInfo : undefined
  }

  const token = await checkAuthLoader();
  const authUrl = process.env.REACT_APP_AUTH_URL
  const response = await fetch(authUrl + '/api/v1/directory/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(directoryItemData)
  });

  const resData = await response.json();

  if (response.status === 401 || response.status === 403 || response.status === 404 || response.status === 409) {
    return resData;
  }

  if (!response.ok) {
    throw json({ message: resData.message }, { status: 500 });
  }
  return redirect('/directory');
}