import { useEffect } from 'react';
import { Link, useLoaderData, useParams } from 'react-router-dom';

import OptionCard from '../directory/OptionCard';

import styles from './FightMatcherSportSelectContent.module.css';

const FightMatcherSportSelectContent = () => {
  const sports = useLoaderData();
  const { eventId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.sportsContainer}>
      <h1>Fight Matcher - Select a Sport</h1>
      <div className={styles.editContainer}>
        <p>Want to add more sports on your event? Click below to edit</p>
        <Link className={styles.editBtn} to={'/dashboard/edit-event/' + eventId}>
          Edit Event
        </Link>
      </div>
      <ul className={styles.sportsList}>
        {sports.map(sport => (
          <li key={sport} className={styles.sportItem}>
            <OptionCard title={sport.replace(/_/g, ' ')} link='sport' />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FightMatcherSportSelectContent;