import { Outlet } from 'react-router-dom';
import { checkAuthLoader } from '../util/auth';

import RequestsContent from '../components/dashboard/RequestsContent';

const Requests = () => {
  return <>
    <Outlet />
    <RequestsContent />
  </>
}

export default Requests;

export const loader = async () => {
  const token = await checkAuthLoader();
  if (token instanceof Response) {
    return token;
  }
  const options = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const authUrl = process.env.REACT_APP_AUTH_URL;

  // Fetch events
  const eventsResponse = await fetch(authUrl + '/api/v1/events/pending', options);
  const eventsResData = await eventsResponse.json();

  // Fetch gyms
  const gymsResponse = await fetch(authUrl + '/api/v1/accounts/gyms');
  const gymsResData = await gymsResponse.json();

  // Create a map for gyms
  const gymsMap = new Map(gymsResData.map(gym => [gym.user_id, gym.organisation]));

  // Get fighter IDs from fights array
  const fightFighterIds = eventsResData.flatMap(fight => [
    fight.fighter_a.fighter_id,
    fight.fighter_b.fighter_id,
  ]);

  // Fetch fighters data
  const fightsFightersResponse = await fetch(authUrl + '/api/v1/fighters/list', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fightFighterIds)
  });
  const fightsFightersResData = await fightsFightersResponse.json();
  const fightsFightersMap = new Map(fightsFightersResData.map(fighter => [fighter.id, fighter]));

  // Integrate fighters and gyms data into events data
  eventsResData.forEach(fight => {
    const fighterAData = fightsFightersMap.get(fight.fighter_a.fighter_id);
    const fighterBData = fightsFightersMap.get(fight.fighter_b.fighter_id);
  
    fight.fighter_a = { 
      ...fight.fighter_a, 
      ...fighterAData, 
      gym_name: gymsMap.get(fight.fighter_a.gym_id) 
    };
  
    fight.fighter_b = { 
      ...fight.fighter_b, 
      ...fighterBData, 
      gym_name: gymsMap.get(fight.fighter_b.gym_id) 
    };
  });
  return eventsResData;
}