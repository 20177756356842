import { json, redirect } from 'react-router-dom';

import { checkAuthLoader } from '../util/auth';

import ConfirmModal from '../components/ui/ConfirmModal';

const ConfirmSubmitFighter = () => {
  return <ConfirmModal title="Submit Fighter?" />
}

export default ConfirmSubmitFighter;

export const action = async ({ params }) => {
  const token = await checkAuthLoader();
  const request = {
    fighter_id: params.fighterId,
    sport: params.sport.replace(/-/g, "_").toUpperCase()
  }
  let options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request),
  };
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const eventsResponse = await fetch(authUrl + '/api/v1/events/submit/' + params.eventId, options);
  if (!eventsResponse.ok) {
    throw json({ message: 'An unexpected error has occurred' }, { status: 500 });
  }
  return redirect('../..');
}