import { useNavigate, useLocation, Outlet, useLoaderData } from 'react-router-dom';
import moment from 'moment-timezone';

import Modal from '../ui/Modal';

import styles from './ActionMatchOptions.module.css';

const ActionMatchOptions = () => {
  const navigate = useNavigate();
  const fight = useLoaderData();
  const location = useLocation();
  const isConfirm = location.pathname.includes('/confirm');

  const handleAccept = () => {
    navigate('confirm/accept');
  }

  const handleDecline = () => {
    navigate('confirm/decline');
  }

  const convertToTimezone = (utcDateTime) => {
    const convertedTime = moment.utc(utcDateTime).tz('Europe/London');
    return convertedTime.format('DD/MM/yyyy');
  }

  return (
    <>
      <Outlet />
      {!isConfirm && (
        <Modal>
          <div className={styles.actions}>
            <h2>Accept Fight?</h2>
            <div className={styles.info}>
              <p className={styles.location}>{fight.address}</p>
              <p className={styles.dateTime}>{convertToTimezone(fight.date_time)}</p>
            </div>
            <button className={styles.acceptBtn} onClick={handleAccept}>Accept</button>
            <button className={styles.declineBtn} onClick={handleDecline}>Decline</button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ActionMatchOptions;
