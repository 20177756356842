import { json, redirect } from 'react-router-dom';

import { checkAuthLoader } from '../util/auth';

import ConfirmModal from '../components/ui/ConfirmModal';

const PulloutFighter = () => {
  return <ConfirmModal title="Pullout Fighter?" />
}

export default PulloutFighter;

export const action = async ({ params }) => {
  const token = await checkAuthLoader();
  let options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const eventsResponse = await fetch(authUrl + '/api/v1/events/decline/' + params.fightId, options);
  if (!eventsResponse.ok) {
    throw json({ message: 'An unexpected error has occurred' }, { status: 500 });
  }
  return redirect('..');
}