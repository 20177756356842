import { useParams, useLoaderData, Link } from 'react-router-dom';
import moment from 'moment-timezone';

import Modal from '../ui/Modal';

import styles from './PreviewMarkerContent.module.css';

const PreviewMarkerContent = () => {
  const { type, id } = useParams();
  const data = useLoaderData();

  const convertToTimezone = (utcDateTime) => {
    const convertedTime = moment.utc(utcDateTime).tz('Europe/London');
    return convertedTime.format('DD/MM/yyyy');
  }

  const joinStrings = (arr) => {
    return arr.map(item => item.replace(/_/g, ' ')).join(', ');
  }

  const profilePicture = type === 'gym' ? data.image_url : data.promotion.image_url;
  const profilePictureSrc = profilePicture ? profilePicture : process.env.PUBLIC_URL + '/authlogo.png';

  return <Modal>
    <div className={styles.previewContainer}>
      <div className={styles.logoSection}>
        <img className={styles.profilePicture} src={profilePictureSrc} alt="ProfilePicture" />
      </div>
      <h2>{type === 'gym' ? data.organisation : data.name}</h2>
      <div className={styles.info}>
        <p className={styles.location}>{data.address}</p>
        {type === 'event' && <>
          <p className={styles.dateTime}>{convertToTimezone(data.date_time)}</p>
          <p className={styles.sports}>Sports: {joinStrings(data.sports)}</p>
        </>}
      </div>
      <Link className={styles.viewMore} to={'/' + type + '/' + id}>
        View More
      </Link>
    </div>
  </Modal>
}

export default PreviewMarkerContent;