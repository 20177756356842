import { Link, useNavigate, useRouteError } from 'react-router-dom';
import { useEffect } from 'react';

import PageContent from '../components/ui/PageContent.jsx';

const Error = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  console.log(error);
  console.log(error.stack);

  let title = 'We are sorry, there has been an error';
  useEffect(() => {
    navigate('/');
  }, [error, navigate]);

return (
  <>
    <PageContent title={title}>
      {/* <p>{message}</p> */}
      {/* <p>{stack}</p> */}
      <h4>If the error persists, please contact <strong>support@fightconnect.com</strong></h4>
      <Link to="/">
        <p>Click here to return to the homepage</p>
      </Link>
    </PageContent>
  </>
);
}

export default Error;
