import { json } from 'react-router-dom';

import RequestResetPasswordForm from '../components/users/RequestResetPasswordForm';

const SendResetPassword = () => {
  return <RequestResetPasswordForm />
}

export default SendResetPassword;

export const action = async ({ request }) => {
  const data = await request.formData();
  const email = data.get('email');
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const authResponse = await fetch(authUrl + '/api/v1/auth/request-password-reset/' + email);
  if (authResponse.status === 401) {
    return { success: false, message: 'Please Check Email'};
  } else if (authResponse.status === 404) {
    return { success: false, message: 'Incorrect Email'}
  }
  if (!authResponse.ok) {
    throw json({ message: 'An unexpected error has occurred' }, { status: 500 });
  }
  return { success: true, message: 'Please Check Email'}
}