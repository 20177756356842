import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import countiesMap from '../../util/counties.json';
import styles from './FightSelector.module.css';

const FightSelector = ({ data, title, handleSelect, handleDeselect, currentTab, fighterA, fighterB, gymA, gymB, selectedFighter, catchWeight, catchWeightRange, weightClass, gender, professional, region, preSelect }) => {
  const submittedFighters = data.submittedFighters;
  const [loading, setLoading] = useState(true);
  const [shuffledFighters, setShuffledFighters] = useState([]);
  const [filteredFighters, setFilteredFighters] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const weightClassMap = {
    "straw_weight": "Strawweight",
    "light_fly_weight": "Light Flyweight",
    "fly_weight": "Flyweight",
    "super_fly_weight": "Super Flyweight",
    "bantam_weight": "Bantamweight",
    "super_bantam_weight": "Super Bantamweight",
    "feather_weight": "Featherweight",
    "super_feather_weight": "Super Featherweight",
    "light_weight": "Lightweight",
    "super_light_weight": "Super Lightweight",
    "welter_weight": "Welterweight",
    "super_welter_weight": "Super Welterweight",
    "middle_weight": "Middleweight",
    "super_middle_weight": "Super Middleweight",
    "light_heavy_weight": "Light Heavyweight",
    "cruiser_weight": "Cruiserweight",
    "heavy_weight": "Heavyweight"
  };

  const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  };

  useEffect(() => {
    if (data && data.activeFighters) {
      setShuffledFighters(shuffleArray([...data.activeFighters]));
    }
  }, [data]);

  useEffect(() => {
    const isCatchWeightRangeValid = catchWeightRange.max >= catchWeightRange.min;

    if (!preSelect && isCatchWeightRangeValid) {
      setFilteredFighters(
        shuffledFighters.filter(fighter => {
          const nameMatches = `${fighter.first_name} ${fighter.last_name}`.toLowerCase().includes(searchTerm.toLowerCase());
          const weightClassMatches = weightClass === "NULL" || fighter.stats.weight_class === weightClass;
          const genderMatches = gender === "NULL" || fighter.gender === gender;
          const professionalMatches = professional === "NULL" || ((fighter.stats.professional && professional === "PROFESSIONAL") || (!fighter.stats.professional && professional === "AMATEUR"));
          const regionMatches = region === "NULL" || (countiesMap[fighter.region] === region || fighter.region === region);
          const notSelectedA = !fighterA || fighter.id !== fighterA;
          const notSelectedB = !fighterB || fighter.id !== fighterB;
          const notGymA = !gymA || fighter.gym_id !== gymA;
          const notGymB = !gymB || fighter.gym_id !== gymB;
          const isCatchWeight = !catchWeight || (fighter.stats.catch_weight_range && fighter.stats.catch_weight_range.min <= catchWeightRange.max && fighter.stats.catch_weight_range.max >= catchWeightRange.min);

          return nameMatches && (catchWeight ? isCatchWeight : weightClassMatches) && genderMatches && professionalMatches && regionMatches && notSelectedA && notSelectedB && notGymA && notGymB &&
            ((currentTab === 'activeFighters') || (currentTab === 'submittedFighters' && submittedFighters && submittedFighters.includes(fighter.id)));
        })
      );
    } else {
      setFilteredFighters(shuffledFighters.filter(fighter => fighter.id === selectedFighter));
    }
    setLoading(false);
  }, [selectedFighter, preSelect, shuffledFighters, searchTerm, weightClass, gender, professional, region, currentTab, submittedFighters, fighterA, fighterB, gymA, gymB, catchWeight, catchWeightRange]);


  const calculateAge = (dobString) => {
    // Parse the date of birth string to a Date object
    const dob = new Date(dobString);
    
    // Get the current date
    const today = new Date();
    
    // Calculate the difference in years
    let age = today.getFullYear() - dob.getFullYear();
    
    // Adjust age if the birthday has not occurred yet this year
    const monthDifference = today.getMonth() - dob.getMonth();
    const dayDifference = today.getDate() - dob.getDate();
    
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }
    
    return age;
  }

  return (
    <div className={styles.fighterList}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <h2>{title}</h2>
          <input
            type="text"
            placeholder="Search fighters by name..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            className={styles.searchBar}
          />
          {filteredFighters.length === 0 && <p className={styles.noResults}>No fighters match this criteria.</p>} 
          {filteredFighters.map(fighter => (
            <div key={fighter.id} className={`${styles.fighter} ${selectedFighter && selectedFighter === fighter.id ? styles.selectedFighter : ''}`}>
              <div className={styles.fighterInfo}>
                <Link to={`/fighter/${fighter.id}`}>
                  {fighter.nickname && <h3 className={styles.fighterName}>{`${fighter.first_name} '${fighter.nickname}' ${fighter.last_name}`}</h3>}
                  {!fighter.nickname && <h3 className={styles.fighterName}>{`${fighter.first_name} ${fighter.last_name}`}</h3>}
                </Link>
                <Link to={`/gym/${fighter.gym_id}`}>
                  <h4 className={styles.gym}>
                    {`Gym: ${fighter.gym_name}`}
                  </h4>
                </Link>
                <p className={styles.professional}>{`Status: ${fighter.stats.professional ? 'Professional' : 'Amateur'}`}</p>
                <p className={styles.region}>{`Region: ${countiesMap[fighter.region] || fighter.region}`}</p>
                {fighter.dob && <p className={styles.experience}>Age: {calculateAge(fighter.dob)}</p>}
                {!fighter.dob && <p className={styles.experience}>Age: N/A</p>}
                <p className={styles.experience}>{`Years Exp: ${fighter.stats.years_experience}`}</p>
                <p className={styles.weightClass}>
                  {`Weight Class: ${weightClassMap[fighter.stats.weight_class]}`}
                </p>
                {fighter.stats.catch_weight_range && <p className={styles.weightClass}>{`Catch Weight: ${fighter.stats.catch_weight_range.min}kg - ${fighter.stats.catch_weight_range.max}kg`}</p>}
                {!fighter.stats.catch_weight_range && <p className={styles.weightClass}>Catch Weight: N/A</p>}
                {fighter.stats.professional && <p className={styles.proRecord}>{`Pro Record: ${fighter.stats.pro_record.wins}-${fighter.stats.pro_record.losses}-${fighter.stats.pro_record.draws}`}</p>}
                {!fighter.stats.professional && <p className={styles.proRecord}>Pro Record: N/A</p>}
                <p className={styles.amateurRecord}>{`Amateur Record: ${fighter.stats.amateur_record.wins}-${fighter.stats.amateur_record.losses}-${fighter.stats.amateur_record.draws}`}</p>
              </div>
              <button
                onClick={() => {
                  if (selectedFighter && selectedFighter === fighter.id) {
                    handleDeselect(fighter);
                  } else {
                    handleSelect(fighter);
                  }
                }}
              >
                {selectedFighter && selectedFighter === fighter.id ? 'Deselect' : 'Select'}
              </button>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default FightSelector;