import { useState, useEffect } from 'react';
import { Link, useRouteLoaderData } from 'react-router-dom';
import { decodeToken } from '../../util/auth';

import OptionCard from './OptionCard';

import styles from './DirectoryContent.module.css';
import regionsList from '../../util/regions';

const DirectoryContent = () => {
  const token = useRouteLoaderData('root');
  const [searchData, setSearchData] = useState('');

  const changeSearchDataHandler = (event) => {
    setSearchData(event.target.value);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let decodedToken = null;
  if (token) {
    decodedToken = decodeToken(token);
  }

  const filteredRegions = regionsList.filter(region => {
    const trimmedSearchData = searchData.trim().toLowerCase();
    const lowerCaseRegion = region.toLowerCase();
    return trimmedSearchData === '' || lowerCaseRegion.includes(trimmedSearchData);
  });

  return (
    <div className={styles.directory}>
      <h1>Event Directory - Choose a Region</h1>
      <div className={styles.search}>
        <input
          id="search"
          type="text"
          name="search"
          value={searchData}
          onChange={changeSearchDataHandler}
          autoComplete='new-password'
          placeholder="Search for region..."
        />
      </div>
      {decodedToken && decodedToken.authorities[0] === 'ROLE_ADMIN' &&
        <Link className={styles.addBtn} to={'/directory/add'}>
          Add Directory Item
        </Link>
      }
      <ul className={styles.regionList}>
        <li key='All' className={styles.regionItem}>
          <OptionCard title={'All'} link='region' />
        </li>
        {filteredRegions.map(region => (
          <li key={region} className={styles.regionItem}>
            <OptionCard title={region} link='region' />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DirectoryContent;
