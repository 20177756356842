import { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { tokenLoader } from '../../util/auth';
import { useSocket } from '../../context/SocketContext';

import DashboardNav from './DashboardNav';
import NotificationCard from './NotificationCard';
import ConfirmModal from '../ui/ConfirmModal';

import styles from './NotificationsContent.module.css';

const NotificationsContent = () => {
  const notifications = useLoaderData();
  const navigate = useNavigate();
  const { fetchUnreadNotificationsCount } = useSocket();

  const [showConfirmReadAll, setShowConfirmReadAll] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleReadAll = () => {
    setShowConfirmReadAll(true);
  }

  const handleCloseModal = () => {
    setShowConfirmReadAll(false);
  }

  const handleConfirmReadAll = async () => {
    const token = await tokenLoader();
    const notificationIds = notifications.map(notification => notification.id);
    const authUrl = process.env.REACT_APP_AUTH_URL;
    const response = await fetch(`${authUrl}/api/v1/notifications/read-all`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(notificationIds),
    });

    if (!response.ok) {
      throw new Error('Reading notifications failed!');
    }
    fetchUnreadNotificationsCount();
    navigate(0);
  }

  const notificationCountText = `${notifications.length} Unread Notification${notifications.length === 1 ? '' : 's'}`;

  return (
    <>
      <DashboardNav />
      {showConfirmReadAll && <ConfirmModal title="Mark all as Read?" onClose={handleCloseModal} onYes={handleConfirmReadAll} />}
      <div className={styles.container}>
        <h1>Your Notifications</h1>
        <h3>{notificationCountText}</h3>
        {notifications.length > 0 && <>
          <button className={styles.actionButton} onClick={handleReadAll}>Mark All Read</button>
        </>}
        <ul className={styles.notificationsList}>
          {notifications.map(notification => (
            <li key={notification.id} className={styles.notificationssListItem}>
              <NotificationCard notification={notification} />
            </li>
          ))}
        </ul>
      </div></>
  )
}

export default NotificationsContent;