import { useEffect } from 'react';
import { Form, Link, useActionData } from 'react-router-dom';

import Modal from '../ui/Modal';

import styles from './ChangePasswordForm.module.css';

const ChangePasswordContent = () => {
  const data = useActionData();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Modal>
      <Form method='post' className={styles.form}>
        <h2>Change Password</h2>
        {data && data.errors && (
          <div className={styles.errors}>
            <ul>
              {Object.values(data.errors).map((err) => (
                <li key={err}>{err}</li>
              ))}
            </ul>
          </div>
        )}
        {data && data.message &&
          <div className={styles.errors}>
            <p>{data.message}</p>
          </div>
        }
        <p>
          <label htmlFor="password">New Password</label>
          <input id="password" type="password" name="password" required />
        </p>
        <p>
          <label htmlFor="confirmPassword">Confirm New Password</label>
          <input id="confirmPassword" type="password" name="confirmPassword" required />
        </p>
        <p className={styles.actions}>
          <Link to="..">Cancel</Link>
          <button>Submit</button>
        </p>
      </Form>
    </Modal>
  );
}

export default ChangePasswordContent;