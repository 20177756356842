import { Outlet } from 'react-router-dom';
import DirectoryContent from '../components/directory/DirectoryContent';

const Directory = () => {
  return <>
    <Outlet />
    <DirectoryContent />
  </>
}

export default Directory;