import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';

import PageContent from '../components/ui/PageContent';

const ConfirmEmail = () => {
  const [showResend, setShowResend] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowResend(true);
    }, 60000); // 60000 milliseconds = 60 seconds

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return (
    <>
    <Outlet />
      <PageContent title='Confirm Email Address'>
        <h3>Please check your email inbox for a link to confirm your account.</h3>
        {showResend && (
          <Link to="resend">
            <p>Click here to resend the email.</p>
          </Link>
        )}
        {!showResend && (
          <p>After 60 seconds you can resend the email.</p>
        )}
      </PageContent>
    </>
  );
}

export default ConfirmEmail;
