import { checkAdminLoader } from '../util/auth';

import ActionUserOptions from '../components/admin/ActionUserOptions';

const ActionUser = () => {
  return <ActionUserOptions />
}

export default ActionUser;

export const loader = async ({ params }) => {
  const token = await checkAdminLoader();
  if (token instanceof Response) {
    return token;
  }
  const options = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const authUrl = process.env.REACT_APP_AUTH_URL
  const authResponse = await fetch(authUrl + '/api/v1/users/status/' + params.userId, options);
  const authResData = await authResponse.json();
  
  return authResData
}