import { Outlet } from 'react-router-dom';
import { checkAuthLoader } from '../util/auth'

import NotificationsContent from '../components/dashboard/NotificationsContent';

const Notifications = () => {
  return <>
    <Outlet />
    <NotificationsContent />
  </>
}

export default Notifications;

export const loader = async () => {
  const token = await checkAuthLoader();
  if (token instanceof Response) {
    return token;
  }
  const options = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const response = await fetch(authUrl + '/api/v1/notifications/unread', options);
  const resData = await response.json();

  resData.sort((a, b) => {
    return new Date(b.timestamp) - new Date(a.timestamp);
  });

  return resData;
}