import { useState, useRef, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Autocomplete } from "@react-google-maps/api";
import { useGoogleMapsContext } from '../ui/GoogleMapsContext';
import MapComponent from './MapComponent';
import ExploreCard from './ExploreCard';
import Footer from "../ui/Footer";

import styles from './ExploreContent.module.css';

const ExploreContent = () => {
  const { isMapsApiLoaded } = useGoogleMapsContext();
  const data = useLoaderData();
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState('');
  const [checkedFilters, setCheckedFilters] = useState({});
  const [locations, setLocations] = useState([]);
  const autocompleteRef = useRef(null);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [searchCoordinates, setSearchCoordinates] = useState(null);
  const [radius, setRadius] = useState('');
  const [mapControls, setMapControls] = useState(true);

  const onLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const changeSearchDataHandler = (event) => {
    setSearchData(event.target.value);
  }

  const changeFilterHandler = (event) => {
    setCheckedFilters({ ...checkedFilters, [event.target.name]: event.target.checked })
  }

  const changeRadiusHandler = (event) => {
    setRadius(event.target.value);
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current !== null) {
      const place = autocompleteRef.current.getPlace();
      if (place.formatted_address) {
        setSelectedLocation(place.formatted_address);
        setSearchCoordinates({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        setRadius('5')
      }
    }
  };

  const handleResetLocation = () => {
    setSelectedLocation('');
    setSearchCoordinates(null);
    setRadius('');
  }

  const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  };

  const filteredLocations = locations.filter((location) => {
    const searchTermMatch =
      searchData.trim() === "" ||
      location.name.toLowerCase().includes(searchData.trim().toLowerCase());

    let typeMatch = false;
    if (!checkedFilters["gymFilter"] && !checkedFilters["eventsFilter"]) {
      typeMatch = true;
    } else {
      typeMatch =
        (checkedFilters["gymFilter"] && location.type === "GYM") ||
        (checkedFilters["eventsFilter"] && location.type === "EVENT");
    }

    const radiusInMeters = radius * 1609.34;
    const radiusMatch =
      !radius ||
      radius.trim() === '' ||
      !searchCoordinates ||
      window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(location.coordinates.latitude, location.coordinates.longitude),
        new window.google.maps.LatLng(searchCoordinates.lat, searchCoordinates.lng)
      ) <= radiusInMeters;

    return searchTermMatch && typeMatch && radiusMatch;
  });

  const handleResize = () => {
    setMapControls(window.innerWidth > 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (data) {
      const shuffledData = shuffleArray([...data]);
      setLocations(shuffledData);
      setLoading(false);
    }
  }, [data]);

  return (
    <>
      <div className={styles.explore}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <h1>Explore</h1>
            <div className={styles.search}>
              <input id="search" type="text" name="search" value={searchData} onChange={changeSearchDataHandler} autoComplete='new-password' placeholder="Search for gyms or events..." />
              <div className={styles.filters}>
                <div>
                  <input id="gymFilter" type="checkbox" name="gymFilter" checked={checkedFilters["gymFilter"] || false} onChange={changeFilterHandler} />
                  <label htmlFor="gymFilter">Gyms</label>
                </div>
                <div>
                  <input id="eventsFilter" type="checkbox" name="eventsFilter" checked={checkedFilters["eventsFilter"] || false} onChange={changeFilterHandler} />
                  <label htmlFor="eventsFilter">Events</label>
                </div>
              </div>
            </div>
            <div className={styles.radiusSearch}>
              <label htmlFor="address">Location Filter</label>
              {isMapsApiLoaded && selectedLocation === '' && (
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                  options={{
                    componentRestrictions: { country: "uk" }
                  }}
                >
                  <input
                    id="address"
                    type="text"
                    name="address"
                    placeholder="Enter starting location..."
                    autoComplete="fight-connect"
                    onKeyPress={handleKeyPress}
                  />
                </Autocomplete>
              )}
              {selectedLocation !== '' && (
                <>
                  <div>
                    <input
                      id="selectedAddress"
                      type="text"
                      name="selectedAddress"
                      value={selectedLocation}
                      readOnly
                    />
                    <select
                      id="radius"
                      name="radius"
                      value={radius}
                      onChange={changeRadiusHandler}
                      disabled={!selectedLocation}
                    >
                      <option value="">Select radius (miles)</option>
                      <option value="1">1 mile</option>
                      <option value="5">5 miles</option>
                      <option value="10">10 miles</option>
                      <option value="20">20 miles</option>
                      <option value="50">50 miles</option>
                    </select>
                  </div>
                  <p className={styles.resetLocation} onClick={handleResetLocation}>Remove location filter</p>
                </>
              )}
            </div>
            <div className={styles.map}>
              <MapComponent
                locations={filteredLocations}
                radius={radius}
                searchCoordinates={searchCoordinates}
                controls={mapControls}
                isExplore={true}
              />
            </div>
            <h3>{filteredLocations.length} Results</h3>
            <ul className={styles.itemsList}>
              {filteredLocations.map(result => (
                <li key={result.id} className={styles.itemsListItem}>
                  <ExploreCard result={result} />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ExploreContent;