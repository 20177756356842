import { json, redirect } from 'react-router-dom';

import AuthForm from '../components/users/AuthForm';

const ResetPassword = () => {
  return (
    <AuthForm />
  );
}

const validateForm = (resetData) => {
  let validationErrors = [];
  if (resetData.password.length < 8) {
    validationErrors.push('Password must be at least 8 characters.');
  }
  if (!/[A-Z]/.test(resetData.password)) {
    validationErrors.push('Password must contain at least 1 upper case letter.');
  }
  if (!/[a-z]/.test(resetData.password)) {
    validationErrors.push('Password must contain at least 1 lower case letter.');
  }
  if (resetData.password !== resetData.confirmPassword) {
    validationErrors.push('Password and confirm password do not match.')
  }
  return validationErrors;
}

export default ResetPassword;

export const action = async ({ params, request }) => {
  const data = await request.formData();
  const resetData = {
    password: data.get('password'),
    confirmPassword: data.get('confirmPassword')
  }

  const validationErrors = validateForm(resetData);
  if (validationErrors.length > 0) {
    return { errors: validationErrors }
  }

  const body = {
    token: params.token,
    password: resetData.password
  }

  const authUrl = process.env.REACT_APP_AUTH_URL
  const authResponse = await fetch(authUrl + '/api/v1/auth/reset-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  });

  const authResData = await authResponse.json();

  if (authResponse.status === 401 || authResponse.status === 403 || authResponse.status === 404 || authResponse.status === 409) {
    return authResData;
  }

  if (!authResponse.ok) {
    throw json({ message: authResData.message }, { status: 500 });
  }

  return redirect('/login');
}