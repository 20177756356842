import { useNavigate, useLocation } from 'react-router-dom';

import styles from './FighterCard.module.css';

const FighterCard = ({ fighter, gym }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isEventPage = location.pathname.includes('/event');

  const handleClick = () => {
    navigate('/fighter/' + fighter.id);
  }

  const handleWithdrawClick = (e) => {
    e.stopPropagation(); // Prevent triggering the card click event
    navigate('withdraw-fighter/' + fighter.id);
  }

  const fighterPictureSrc = fighter.image_url ? fighter.image_url : process.env.PUBLIC_URL + '/authlogo.png';

  return <div className={styles.card}>
    {isEventPage && gym && gym === fighter.gym_id && (
      <div className={styles.withdrawButton} onClick={handleWithdrawClick}>X</div>
    )}
    <img
      className={styles.fighterLogo}
      src={`${fighterPictureSrc}?timestamp=${new Date().getTime()}`}
      alt="Fighter Logo"
    />
    <div className={styles.info}>
      <h1>
        {fighter.first_name} {fighter.last_name}
      </h1>
      {isEventPage && <p>{fighter.sport.replace(/_/g, ' ').toUpperCase()}</p>}
      {/* <p>
        Pro Record: {fighter.pro_record.wins} - {fighter.pro_record.losses} -{" "}
        {fighter.pro_record.draws}
      </p>
      <p>
        Amateur Record: {fighter.amateur_record.wins} -{" "}
        {fighter.amateur_record.losses} - {fighter.amateur_record.draws}
      </p> */}
      <button className={styles.actionButton} onClick={handleClick}>View</button>
    </div>
  </div>
}

export default FighterCard;