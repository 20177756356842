import { useState, useEffect } from 'react';
import { Form, Link, useRouteLoaderData, useLoaderData, useActionData, useNavigation, useLocation } from 'react-router-dom';
import { decodeToken } from '../../util/auth';

import counties from '../../util/counties.json';

import styles from './ProfileContent.module.css';

const Profile = () => {
  const token = useRouteLoaderData('root');
  const role = decodeToken(token).authorities[0];
  const data = useLoaderData();
  const actionData = useActionData()
  const navigation = useNavigation();
  const isSubmitting = navigation.state === 'submitting';
  const location = useLocation();
  const isProfile = location.pathname.includes('/profile');

  const [postcode, setPostcode] = useState('');
  const [suggestions, setSuggestions] = useState('');

  const [orgName, setOrgName] = useState(data?.orgName || '');
  const [orgType, setOrgType] = useState('NULL'); // Just used for add account
  const [areaCode, setAreaCode] = useState(data?.areaCode || '+44');
  const [phoneNumber, setPhoneNumber] = useState(data?.phoneNumber || '');
  const [selectedAddress, setSelectedAddress] = useState(data?.address || '');
  const [province, setProvince] = useState(data?.region || '');
  const [showProvince, setShowProvince] = useState(false);
  const [contactEmail, setContactEmail] = useState(data?.contactEmail || '');
  const [xPlatform, setXPlatform] = useState(data?.xPlatform || '');
  const [facebook, setFacebook] = useState(data?.facebook || '');
  const [instagram, setInstagram] = useState(data?.instagram || '');
  const [youtube, setYoutube] = useState(data?.youtube || '');
  const [tapologyUrl, setTapologyUrl] = useState(data?.tapology || '');
  const [emailNotifications, setEmailNotifications] = useState(data?.emailNotifications || false);
  const [smsNotifications, setSmsNotifications] = useState(data?.smsNotifications || false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleTouchMove = (event) => {
      if (event.target.closest('.addressList')) {
        event.stopPropagation();
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  if (actionData) {
    window.scrollTo(0, 0);
  }

  const handlePostcodeChange = async (e) => {
    const value = e.target.value;
    setPostcode(value);
    if (value.length > 3) {
      try {
        const response = await fetch(`https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?Key=${process.env.REACT_APP_LOQATE_API_KEY}&Text=${e.target.value}&IsMiddleware=false&Countries=GB&Language=en-gb&Container=`);
        const result = await response.json();
        setSuggestions(result.Items);
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  }

  const handleSuggestionsClick = async (suggestion) => {
    if (suggestion.Type === 'Address') {
      try {
        const response = await fetch(`https://api.addressy.com/Capture/Interactive/Retrieve/v1.2/json3.ws?Key=${process.env.REACT_APP_LOQATE_API_KEY}&Id=${suggestion.Id}`);
        const result = await response.json();
        setSelectedAddress(result.Items[0].Label);
        if (!result.Items[0].Province) {
          setShowProvince(true);
        } else {
          setShowProvince(false);
          setProvince(result.Items[0].Province || '');
        }
      } catch (error) {
        console.error('Error fetching address:', error);
      }
    } else {
      try {
        const response = await fetch(`https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?Key=${process.env.REACT_APP_LOQATE_API_KEY}&Text=${suggestion.Text}&IsMiddleware=false&Countries=GB&Language=en-gb&Container=${suggestion.Id}`);
        const result = await response.json();
        setSuggestions(result.Items);
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    }
  }

  const handleResetAddress = () => {
    setPostcode('');
    setSuggestions([]);
    setSelectedAddress('');
    setProvince('');
    setShowProvince(false);
  }

  return (
    <>
      <div className={styles.background} />
      <div className={styles.container}>
        <Form method="post" className={styles.form}>
          <img className={styles.authlogo} src={process.env.PUBLIC_URL + '/authlogo.png'} alt="Logo" />
          <h1>{isProfile ? 'Your Profile' : 'Add New Account'}</h1>
          {actionData && actionData.errors && (
            <div className={styles.errors}>
              <ul>
                {Object.values(actionData.errors).map((err) => (
                  <li key={err}>{err}</li>
                ))}
              </ul>
            </div>
          )}
          {actionData && actionData.message &&
            <div className={actionData.success ? styles.success : styles.errors}>
              <p>{actionData.message}</p>
            </div>
          }
          <p>
            <label htmlFor="orgName">Organisation Name</label>
            <input id="orgName" type="text" name="orgName" value={orgName} onChange={(event) => setOrgName(event.target.value)} required />
          </p>

          {!isProfile && <p>
            <label htmlFor="orgType">Organisation Type</label>
            <select id="orgType" name="orgType" onChange={(event) => setOrgType(event.target.value)}>
              <option value="NULL">Please select an organisation type...</option>
              <option value="gym">Gym</option>
              <option value="promotion">Promotion</option>
            </select>
          </p>}

          {isProfile && <p>
            <label>Profile Picture</label>
            {data.imageUrl ? (
              <img className={styles.profilePicture} src={`${data.imageUrl}?timestamp=${new Date().getTime()}`} alt="ProfilePicture" />
            ) : null}
            <Link className={styles.link} to='upload-image'>
              {data.imageUrl ? "Click here to change your profile picture" : "Click here to upload a profile picture"}
            </Link>
          </p>}

          {((isProfile && role === 'ROLE_GYM') || (!isProfile && orgType === 'gym')) && selectedAddress === '' &&
            <>
              <label htmlFor="postcode">Search Address</label>
              <input
                id="postcode"
                type="text"
                name="postcode"
                placeholder="Start typing your address or postcode..."
                value={postcode}
                onChange={handlePostcodeChange}
                autoComplete="off"
              />
              {suggestions.length > 0 && (
                <ul className={styles.addressList}>
                  {suggestions.map((suggestion) => (
                    <li key={suggestion.Id} onClick={() => handleSuggestionsClick(suggestion)}>
                      {suggestion.Text} - {suggestion.Description}
                    </li>
                  ))}
                </ul>
              )}
            </>
          }
          {((isProfile && role === 'ROLE_GYM') || (!isProfile && orgType === 'gym')) && selectedAddress !== '' &&
            <>
              <p>
                <label htmlFor="address">Address</label>
                <input id="address" type="text" name="address" value={selectedAddress.replace(/\n/g, ', ')} readOnly />
                {showProvince ? (
                  <>
                    <label htmlFor="province">Select County</label>
                    <select id="province" name="province" value={province} onChange={(e) => setProvince(e.target.value)} required>
                      <option value="">Please select a county...</option>
                      {Object.keys(counties).map((county) => (
                        <option key={county} value={county}>{county}</option>
                      ))}
                    </select>
                  </>
                ) : (
                  <input type="hidden" name="province" value={province} />
                )}
              </p>
              <p className={styles.resetaddress} onClick={handleResetAddress}>Click here to search for another address</p>
            </>
          }
          <hr />
          <p>
            <label htmlFor="contactEmail">Contact Email (for public page)</label>
            <input id="contactEmail" type="email" name="contactEmail" value={contactEmail} onChange={(event) => setContactEmail(event.target.value)} required />
          </p>
          <>
            <label htmlFor="phoneNumber">Phone Number (for notifications only)</label>
            <div className={styles.phoneInput}>
              <select className={styles.areaCode} id="areaCode" name="areaCode" value={areaCode} onChange={(event) => setAreaCode(event.target.value)}>
                <option value="+44">+44</option>
              </select>
              <input id="phoneNumber" type="text" name="phoneNumber" value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)} required />
            </div>
          </>
          {isProfile &&
            <>
              <p>
                <label htmlFor="instagram">Instagram Link</label>
                <input id="instagram" type="text" name="instagram" value={instagram} onChange={(event) => setInstagram(event.target.value)} />
              </p>
              <p>
                <label htmlFor="facebook">Facebook Link</label>
                <input id="facebook" type="text" name="facebook" value={facebook} onChange={(event) => setFacebook(event.target.value)} />
              </p>
              <p>
                <label htmlFor="xPlatform">X Platform Link</label>
                <input id="xPlatform" type="text" name="xPlatform" value={xPlatform} onChange={(event) => setXPlatform(event.target.value)} />
              </p>
              <p>
                <label htmlFor="youtube">YouTube Link</label>
                <input id="youtube" type="text" name="youtube" value={youtube} onChange={(event) => setYoutube(event.target.value)} />
              </p>
              <p>
                <label htmlFor="tapology">Tapology Link</label>
                <input id="tapology" type="text" name="tapology" value={tapologyUrl} onChange={(event) => setTapologyUrl(event.target.value)} />
              </p>
              <p>
                <label htmlFor="emailNotifications">Email Notifications</label>
                <select id="emailNotifications" name="emailNotifications" value={emailNotifications} onChange={(event) => setEmailNotifications(event.target.value)}>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </p>
              <p>
                <label htmlFor="smsNotifications">SMS Notifications</label>
                <select id="smsNotifications" name="smsNotifications" value={smsNotifications} onChange={(event) => setSmsNotifications(event.target.value)}>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </p>
            </>
          }
          <div className={styles.actions}>
            {isProfile && <div className={styles.links}>
              <Link className={styles.link} to='change-password'>
                Change Password
              </Link>
              <Link className={styles.link} to='change-email'>
                Change User Email
              </Link>
            </div>
            }
            <button disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : isProfile ? 'Update Details' : 'Add Account'}
            </button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Profile;