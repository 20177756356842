import { useState, useEffect } from 'react';
import { useLoaderData, useRouteLoaderData, Link, useNavigate } from 'react-router-dom';
import { decodeToken, tokenLoader } from '../../util/auth';
import moment from 'moment-timezone';

import ExploreCard from './ExploreCard';
import Footer from "../ui/Footer";

import styles from './PromotionContent.module.css';

const PromotionContent = () => {
  const token = useRouteLoaderData('root');
  const promotion = useLoaderData();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("upcomingEvents");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleMessagePromotion = async () => {
    const latestToken = await tokenLoader();
    const authUrl = process.env.REACT_APP_AUTH_URL;
    try {
      const response = await fetch(`${authUrl}/api/v1/inbox/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${latestToken}`
        },
        body: JSON.stringify({ participants: [decodedToken.activeRoleUserId, promotion.user_id] })
      });

      if (!response.ok) {
        throw new Error('Failed to create chat');
      }

      const data = await response.json();
      const inboxId = data.inbox_created;

      navigate(`/inbox?selectedInbox=${inboxId}`);
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };

  const isFutureEvent = (dateString) => {
    const convertedTime = moment.utc(dateString).tz('Europe/London');
    const eventDate = convertedTime.toDate();
    eventDate.setHours(0, 0, 0, 0); // ignore time

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // ignore time

    return eventDate > currentDate;
  }

  let decodedToken = null;
  if (token) {
    decodedToken = decodeToken(token);
  }

  const profilePictureSrc = promotion.image_url ? promotion.image_url : process.env.PUBLIC_URL + '/authlogo.png';

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.logoSection}>
            <img className={styles.profilePicture} src={`${profilePictureSrc}?timestamp=${new Date().getTime()}`} alt="ProfilePicture" />
          </div>
          <div className={styles.titleSection}>
            <h1>{promotion.organisation}</h1>
            <ul className={styles.info}>
              <li>Contact Email: {promotion.contact_email}</li>
              <br />
              <div className={styles.infoLinks}>
                {promotion.instagram &&
                  <a href={promotion.instagram.startsWith('http://') || promotion.instagram.startsWith('https://') ? promotion.instagram : `https://${promotion.instagram}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={process.env.PUBLIC_URL + '/instagram-icon.png'} alt="Logo" />
                  </a>}
                {promotion.facebook &&
                  <a href={promotion.facebook.startsWith('http://') || promotion.facebook.startsWith('https://') ? promotion.facebook : `https://${promotion.facebook}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={process.env.PUBLIC_URL + '/facebook-icon.png'} alt="Logo" />
                  </a>}
                {promotion.x_platform &&
                  <a href={promotion.x_platform.startsWith('http://') || promotion.x_platform.startsWith('https://') ? promotion.x_platform : `https://${promotion.x_platform}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={process.env.PUBLIC_URL + '/x-icon.png'} alt="Logo" />
                  </a>}
                {promotion.youtube &&
                  <a href={promotion.youtube.startsWith('http://') || promotion.youtube.startsWith('https://') ? promotion.youtube : `https://${promotion.youtube}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={process.env.PUBLIC_URL + '/youtube-icon.png'} alt="Logo" />
                  </a>}
              </div>
              {promotion.tapology_url &&
                <a href={promotion.tapology_url.startsWith('http://') || promotion.tapology_url.startsWith('https://') ? promotion.tapology_url : `https://${promotion.tapology_url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Tapology
                </a>}
            </ul>
            {decodedToken && decodedToken.activeRoleUserId === promotion.user_id &&
              <Link className={styles.addEventBtn} to="/dashboard/add-event">
                Add Event
              </Link>
            }
            {decodedToken && decodedToken.activeRoleUserId !== promotion.user_id && (
              <button className={styles.messageBtn} onClick={handleMessagePromotion}>
                Send Message
              </button>
            )}
            <hr />
            <h2>{promotion.events.length} TRACKED EVENT{promotion.events.length !== 1 ? 'S' : ''}</h2>
          </div>
        </div>
        <div className={styles.tabsContainer}>
          <button
            className={`${styles.tab} ${activeTab === "upcomingEvents" ? styles.activeTab : ""}`}
            onClick={() => setActiveTab("upcomingEvents")}
          >
            Upcoming Events
          </button>
          <button
            className={`${styles.tab} ${activeTab === "pastEvents" ? styles.activeTab : ""}`}
            onClick={() => setActiveTab("pastEvents")}
          >
            Previous Events
          </button>
        </div>
        {activeTab === "upcomingEvents" && (
          <ul className={styles.eventsList}>
            {promotion.events.filter(event => isFutureEvent(event.date_time)).map(event => (
              <li key={event.id} className={styles.eventsListItem}>
                <ExploreCard result={{ ...event, type: 'EVENT' }} />
              </li>
            ))}
          </ul>
        )}
        {activeTab === "pastEvents" && (
          <ul className={styles.eventsList}>
            {promotion.events.filter(event => !isFutureEvent(event.date_time)).map(event => (
              <li key={event.id} className={styles.eventsListItem}>
                <ExploreCard result={{ ...event, type: 'EVENT' }} />
              </li>
            ))}
          </ul>
        )}
      </div>
      <Footer />
    </>
  );
}

export default PromotionContent;