import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import styles from './ExploreCard.module.css';

const ExploreCard = ({ result }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (result.type === 'EVENT') {
      navigate('/event/' + result.id);
    } else if (result.type === 'GYM') {
      navigate('/gym/' + result.id);
    }
  }

  const convertToTimezone = (utcDateTime) => {
    const convertedTime = moment.utc(utcDateTime).tz('Europe/London');
    return convertedTime.format('DD/MM/yyyy');
  }

  const typeDisplayName = result.type.charAt(0).toUpperCase() + result.type.slice(1).toLowerCase();
  let shortAddress;
  if (result.address.includes(',')) {
    const addressParts = result.address.split(',');
    shortAddress = addressParts[0] + ', ' + addressParts[1];
  } else {
    shortAddress = result.address;
  }
  const date = result.type === 'EVENT' ? convertToTimezone(result.date_time) : null;
  
  return <div className={styles.card}>
    <h1>{result.name}</h1>
    <div className={styles.info}>
      <p className={styles.location}>{shortAddress}</p>
      {result.type === 'EVENT' && (
        <p className={styles.dateTime}>{date}</p>
      )}
    </div>
    <button className={styles.actionButton} onClick={handleClick}>View {typeDisplayName}</button>
  </div>
}

export default ExploreCard;