import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Modal from '../ui/Modal';

import styles from './ImageCrop.module.css';

const ImageCrop = ({ imageSrc, onSave, onClose }) => {
  const [crop, setCrop] = useState({
    unit: 'px',
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    aspect: 1
  });
  const [isSaving, setIsSaving] = useState(false);

  const imageRef = useRef(null);
  const hasLoadHandlerRun = useRef(false);

  const [naturalWidth, setNaturalWidth] = useState(0);
  const [naturalHeight, setNaturalHeight] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const onImageLoad = async (e) => {
    if (!e.currentTarget.complete || e.currentTarget.naturalWidth === 0) {
      console.error("Image not fully loaded.");
      return;
    }

    if (hasLoadHandlerRun.current) {
      return;
    }

    imageRef.current = e.currentTarget;

    const displayedWidth = imageRef.current.clientWidth;
    const displayedHeight = imageRef.current.clientHeight;
    const size = Math.min(displayedWidth, displayedHeight);

    const newCrop = {
      unit: 'px',
      x: (displayedWidth - size) / 2,
      y: (displayedHeight - size) / 2,
      width: size,
      height: size,
      aspect: 1
    };

    setCrop(newCrop);
    setNaturalWidth(imageRef.current.naturalWidth);
    setNaturalHeight(imageRef.current.naturalHeight);
    setClientWidth(imageRef.current.clientWidth);
    setClientHeight(imageRef.current.clientHeight);
    hasLoadHandlerRun.current = true;
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const saveCrop = async () => {
    if (!crop.width || !crop.height) {
      console.error("Crop dimensions are incomplete.");
      return;
    }

    setIsSaving(true);
    try {
      if (imageRef.current) {
        await onSave(imageSrc, clientWidth, clientHeight, naturalWidth, naturalHeight, crop);
      }
    } catch (error) {
      console.error("Error during image processing:", error);
    }
  }

  const handleImageError = (error) => {
    console.error("Error loading image:", error);
  };

  return (
    <Modal onClose={onClose}>
      <div className={styles.container}>
        <h2>Image Cropper</h2>
        <p>Crop your image and then hit Save Image...</p>
        <ReactCrop
          className={styles.crop}
          aspect={1}
          ruleOfThirds={true}
          crop={crop}
          onChange={onCropChange}>
          <img alt="" style={{ width: "100%", maxHeight: "80vh", objectFit: "contain" }} src={URL.createObjectURL(imageSrc)} onLoad={onImageLoad} />
        </ReactCrop>
        <button
          className={`${styles.saveBtn} ${isSaving ? styles.disabledBtn : ''}`}
          onClick={saveCrop}
          onError={handleImageError}
          disabled={isSaving}>
          {isSaving ? 'Uploading...' : "Save Image"}
        </button>

      </div>
    </Modal>
  );
};

export default ImageCrop;
