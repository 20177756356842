
import styles from './FighterStatCard.module.css';

const FighterStatCard = ({ sport, weightClass, stats }) => {
  return <div className={styles.card}>
    <h1>{sport} Stats</h1>
    <li className={styles.weightClass}>{`Weight Class: ${weightClass}`}</li>
    {stats.catch_weight_range && <li className={styles.weightClass}>{`Catch Weight: ${stats.catch_weight_range.min}KG-${stats.catch_weight_range.max}KG`}</li>}
    {!stats.catch_weight_range && <li className={styles.weightClass}>Catch Weight: N/A</li>}
    <li className={styles.professional}>{`Professional: ${stats.professional ? 'Yes' : 'No'}`}</li>
    <li className={styles.experience}>{`Years Exp: ${stats.years_experience}`}</li>
    {stats.professional && <li className={styles.proRecord}>Pro Record: {stats.pro_record.wins} - {stats.pro_record.losses} - {stats.pro_record.draws}</li>}
    {!stats.professional && <li className={styles.proRecord}>Pro Record: N/A</li>}
    <li className={styles.amateurRecord}>Amateur Record: {stats.amateur_record.wins} - {stats.amateur_record.losses} - {stats.amateur_record.draws}</li>
  </div>
}

export default FighterStatCard;