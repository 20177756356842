import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { useGoogleMapsContext } from '../ui/GoogleMapsContext';

import mapStyle from './MapStyle';

const defaultCenter = {
  lat: 51.5074,
  lng: -0.1278,
};

const MapComponent = ({ locations, radius, searchCoordinates, height, width, controls, isExplore }) => {
  const { isMapsApiLoaded } = useGoogleMapsContext();
  const mapRef = useRef(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [resizeMap, setResizeMap] = useState(false);
  const navigate = useNavigate();

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: controls,
    streetViewControl: controls,
    styles: mapStyle,
    gestureHandling: 'cooperative'
  };

  const containerStyle = {
    width: width || '100%',
    height: height || '400px',
    borderRadius: '9px'
  };

  useEffect(() => {
    if (!initialLoad) {
      if (mapRef.current) {
        const center = searchCoordinates || defaultCenter;
        const bounds = new window.google.maps.LatLngBounds();

        if (locations.length > 0) {
          locations.forEach(location => {
            const { latitude, longitude } = location.coordinates;
            bounds.extend(new window.google.maps.LatLng(latitude, longitude));
          });
        }

        const padding = 75;
        mapRef.current.fitBounds(bounds, padding);

        const onIdleListener = mapRef.current.addListener('idle', () => {
          if (mapRef.current.getZoom() > 14) {
            mapRef.current.setZoom(14);
          }
          if (locations.length === 0) {
            mapRef.current.setZoom(2);
          }
          // Remove the listener after it has been called once
          window.google.maps.event.removeListener(onIdleListener);
        });

        if (locations.length <= 1) {
          const rad = locations.length === 1 || (locations.length === 0 && searchCoordinates) ? (radius !== '' ? radius : 5) : null;
          const zoom = rad ? Math.round(14 - Math.log(rad) / Math.LN2 - Math.log(Math.cos(center.lat * Math.PI / 180)) / Math.LN2) : 14;

          mapRef.current.setZoom(zoom);
          if (locations.length === 0) mapRef.current.setCenter(center);
          if (rad) setResizeMap(true);
        }
      }
    };
  }, [locations, initialLoad, radius, searchCoordinates]);

  useEffect(() => {
    let timeoutId;
    if (resizeMap) {
      timeoutId = setTimeout(() => {
        setResizeMap(false);
      }, 100);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [resizeMap]);

  const markerSize = 30;

  return (
    isMapsApiLoaded && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={searchCoordinates || defaultCenter}
        zoom={10}
        options={mapOptions}
        resize={resizeMap}
        onLoad={mapInstance => {
          mapRef.current = mapInstance;
          setTimeout(() => {
            setInitialLoad(false);
          }, 0);
        }}
      >
        {locations.map((location) => {
          const { latitude, longitude } = location.coordinates;
          const markerIcon = {
            url: process.env.PUBLIC_URL + location.type === 'GYM' ? '/glove.png' : '/cage.png',
            scaledSize: new window.google.maps.Size(markerSize, markerSize),
            anchor: new window.google.maps.Point(markerSize / 2, markerSize / 2)
          };
          return (
            <MarkerF
              key={location.id}
              position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
              icon={markerIcon}
              onClick={() => {
                if (isExplore) {
                  if (location.type === 'GYM') {
                    navigate(`preview/gym/${location.id}`);
                  } else if (location.type === 'EVENT') {
                    navigate(`preview/event/${location.id}`);
                  }
                }
              }}
            />
          );
        })}
      </GoogleMap>
    )
  )
}

export default MapComponent;