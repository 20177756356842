import { useRouteLoaderData } from 'react-router-dom';
import { decodeToken } from '../../util/auth';

import AdminPanel from '../admin/AdminPanel';
import PromotionContent from '../explore/PromotionContent';
import GymContent from '../explore/GymContent';
import DashboardNav from './DashboardNav';

const DashboardContent = () => {
  const token = useRouteLoaderData('root');
  const role = decodeToken(token).authorities[0];

  const RoleComponentMap = {
    'ROLE_ADMIN': AdminPanel,
    'ROLE_PROMOTION': PromotionContent,
    'ROLE_GYM': GymContent,
  };

  const RoleComponent = RoleComponentMap[role];

  return (
    <>
      <DashboardNav />
      {RoleComponent && <RoleComponent />}
    </>
  );
}

export default DashboardContent;