import { json } from 'react-router-dom';
import { checkAuthLoader } from '../util/auth';
import { geocodeAddress } from '../util/maps';
import { redirect } from 'react-router-dom';
import moment from 'moment-timezone';

import EventForm from "../components/dashboard/EventForm";

const AddEvent = () => {
  return <EventForm />
}

const tapologyValidUrls = [
  "https://tapology.com/",
  "https://www.tapology.com/"
];

const validateForm = (eventData) => {
  let validationErrors = [];
  if (!eventData.address || eventData.address === null || eventData.address === '') {
    validationErrors.push('Address must be selected. Please search and select from the suggestions.');
  }
  if (eventData.sports === null || eventData.sports.length < 1) {
    validationErrors.push('At least one sport must be selected.');
  }
  if (eventData.tapology_url && !tapologyValidUrls.some(url => eventData.tapology_url.startsWith(url))) {
    validationErrors.push("Tapology link is invalid.");
  }
  return validationErrors;
}

function formatDateTime(date, time) {
  const dateTimeString = `${date} ${time}`;
  const dateTime = moment.tz(dateTimeString, 'Europe/London');
  return dateTime.toISOString();
}

export default AddEvent;

export const action = async ({ request }) => {
  const data = await request.formData();

  let weighIn = data.get('sameWeighInDate') === 'true' ? formatDateTime(data.get('date'), data.get('time')) : formatDateTime(data.get('weighInDate'), data.get('weighInTime'));

  let eventData = {
    name: data.get('name'),
    address: data.get('address'),
    date_time: formatDateTime(data.get('date'), data.get('time')),
    sports: data.getAll('sports').filter(sport => sport !== ''),
    weigh_in: weighIn,
    weigh_in_type: data.get('weighInType'),
    tapology_url: data.get('tapologyUrl')
  }

  const validationErrors = validateForm(eventData);
  if (validationErrors.length > 0) {
    return { message: validationErrors, success: false }
  }

  const geoLocation = await geocodeAddress(eventData.address);
  eventData = {
    ...eventData,
    coordinates: {
      latitude: geoLocation.Latitude,
      longitude: geoLocation.Longitude
    }
  }

  const token = await checkAuthLoader();
  const authUrl = process.env.REACT_APP_AUTH_URL
  const response = await fetch(authUrl + '/api/v1/events/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(eventData)
  });

  const resData = await response.json();

  if (response.status === 401 || response.status === 403 || response.status === 404 || response.status === 409) {
    return resData;
  }

  if (!response.ok) {
    throw json({ message: resData.message }, { status: 500 });
  }
  return redirect('/dashboard');
}