import { Outlet, redirect } from 'react-router-dom';

import AuthForm from '../components/users/AuthForm';

const Login = () => {
  return (
    <>
      <Outlet />
      <AuthForm />
    </>
  );
}

export default Login;

export const action = async() => {
  return redirect('/dashboard');
}