import { redirect } from 'react-router-dom';
import { checkAuthLoader } from '../util/auth';

import ConfirmModal from '../components/ui/ConfirmModal';

const ConfirmTitleFight = () => {
 return <ConfirmModal />
}

export default ConfirmTitleFight;

export const action = async ({ params }) => {
  const token = await checkAuthLoader();
  let options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const status = params.status === 'set' ? true : false;
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const eventsResponse = await fetch(authUrl + '/api/v1/events/fights/' + params.fightId + '/title/' + status, options);
  const eventsResData = await eventsResponse.json()
  if (!eventsResponse.ok) {
    return eventsResData
  }
  return redirect('..');
}