import { Outlet } from 'react-router-dom';

import { checkAdminLoader } from '../util/auth';

import AdminPanel from '../components/admin/AdminPanel';

const Admin = () => {
  return (
    <>
      <Outlet />
      <AdminPanel />
    </>
  )
}

export default Admin;

export const loader = async () => {
  const token = await checkAdminLoader();
  if (token instanceof Response) {
    return token;
  }
  const options = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const authUrl = process.env.REACT_APP_AUTH_URL
  const authResponse = await fetch(authUrl + '/api/v1/users/list', options);
  const authResData = await authResponse.json();

  return authResData;
}