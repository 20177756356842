import { useState } from 'react';
import { useLoaderData, useParams, useNavigate } from 'react-router-dom';
import Modal from '../ui/Modal';

import styles from './FighterRequestMatchContent.module.css';

const FighterRequestMatchContent = () => {
  const data = useLoaderData();
  const navigate = useNavigate();
  const { fighterId } = useParams();

  const [sport, setSport] = useState('NULL');
  const [eventId, setEventId] = useState('NULL');

  const handleSubmit = () => {
    if (sport !== 'NULL' && eventId !== 'NULL') {
      navigate('/event/' + eventId + '/fight-matcher/sport/' + sport.replace(/_/g, '-').toLowerCase() + '/' + fighterId);
    }
  };

  return (
    <Modal>
      <div className={styles.matchRequest}>
        <h2>Request a Match</h2>
        {data && data.sports.length > 0 && data.events.length > 0 ? (
          <>
            <select id="sport" name="sport" onChange={(event) => setSport(event.target.value)}>
              <option value="NULL">Select a Sport</option>
              {data.sports.map(sport => (
                <option key={sport} value={sport}>{sport.replace(/_/g, ' ').toUpperCase()}</option>
              ))}
            </select>
            {sport !== 'NULL' &&
              <select id="event" name="event" onChange={(event) => setEventId(event.target.value)}>
                <option value="NULL">Select an Event</option>
                {data.events.filter(event => event.sports.includes(sport)).map(event => (
                  <option key={event.name} value={event.id}>{event.name}</option>
                ))}
              </select>
            }
          </>
        ) : (
          <p className={styles.empty}>None of your upcoming events match the fighter's sports.</p>
        )}
        <button onClick={handleSubmit} disabled={sport === 'NULL' || eventId === 'NULL'}>Choose Opponent</button>
      </div>
    </Modal>
  );
}

export default FighterRequestMatchContent;
