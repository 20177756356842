import React, { useState } from 'react';
import { useRouteLoaderData, Link, useNavigate, useLocation } from 'react-router-dom';
import ImageDrop from '../ui/ImageDrop';
import ImageCrop from '../ui/ImageCrop';
import { tokenLoader, decodeToken } from '../../util/auth';

import styles from './ImageUpload.module.css';

const ImageUpload = ({ back, upload, heading, overrideName }) => {
  const token = useRouteLoaderData('root');
  const navigate = useNavigate();
  const location = useLocation();
  const isProfile = location.pathname.includes('/profile');
  const [imageType, setImageType] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  let decodedToken = null;
  if (token) {
    decodedToken = decodeToken(token);
  }

  const handleDrop = (file) => {
    setSelectedImage(file);
    setImageType(file.type);
  };

  const handleCloseCrop = () => {
    setSelectedImage(null);
    setImageType(null);
  }

  const handleSaveCrop = async (originalImage, clientWidth, clientHeight, naturalWidth, naturalHeight, cropData) => {
    const latestToken = await tokenLoader();
    const { x, y, width, height } = cropData; // Assuming cropData contains these values
    const name = isProfile ? decodedToken?.activeRoleUserId : overrideName;
    const fileExtension = determineFileExtension(originalImage.type);
    const fileName = `${name}_${upload}_picture.${fileExtension}`;
    const imageFile = new File([originalImage], fileName, { type: originalImage.type });

    const image = new Image();
    image.src = URL.createObjectURL(imageFile);
    image.onload = async () => {
      // Calculate the scale factors based on the actual dimensions
      const scaleX = naturalWidth / clientWidth;
      const scaleY = naturalHeight / clientHeight;

      // Apply the scale to crop dimensions
      const scaledX = Math.round(x * scaleX);
      const scaledY = Math.round(y * scaleY);
      let scaledWidth = Math.round(width * scaleX);
      let scaledHeight = Math.round(height * scaleY);

      // Ensure crop dimensions do not exceed the actual image dimensions
      if (scaledX + scaledWidth > naturalWidth) {
        scaledWidth = naturalWidth - scaledX;
      }
      if (scaledY + scaledHeight > naturalHeight) {
        scaledHeight = naturalHeight - scaledY;
      }

      const formData = new FormData();
      formData.append('image', imageFile);
      formData.append('cropX', scaledX);
      formData.append('cropY', scaledY);
      formData.append('cropWidth', scaledWidth);
      formData.append('cropHeight', scaledHeight);

      const authUrl = process.env.REACT_APP_AUTH_URL;
      try {
        const uploadResponse = await fetch(`${authUrl}/api/v1/image/upload-${upload}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${latestToken}`
          },
          body: formData,
        });

        if (!uploadResponse.ok) {
          throw new Error('Image upload failed');
        }

        navigate(back);
      } catch (error) {
        console.error('Error uploading the image:', error);
      }
    };
    image.onerror = () => {
      console.error('Error loading image for processing');
    };
  };


  const determineFileExtension = (mimeType) => {
    switch (mimeType) {
      case 'image/jpeg':
        return '.jpg';
      case 'image/png':
        return '.png';
      default:
        return '.' + mimeType.split('/')[1];
    }
  };

  return (
    <div className={styles.container}>
      <Link className={styles.backBtn} to={back}>
        Go Back
      </Link>
      <h1>{heading}</h1>
      <ImageDrop onDrop={handleDrop} />
      {selectedImage && imageType && (
        <ImageCrop imageSrc={selectedImage} type={imageType} onSave={handleSaveCrop} onClose={handleCloseCrop} />
      )}
    </div>
  );
}

export default ImageUpload;