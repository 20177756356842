import { redirect } from 'react-router-dom';

import ConfirmSwitchAccountModal from '../components/users/ConfirmSwitchAccountModal';

const ConfirmSwitchAccount = () => {
  return <ConfirmSwitchAccountModal />
}

export default ConfirmSwitchAccount;

export const action = async () => {
    return redirect('/dashboard');
}