import { Form, useNavigate, useActionData } from 'react-router-dom';

import Modal from '../ui/Modal';

import styles from './ConfirmModal.module.css';

const ConfirmModal = ({ title, onClose, onYes, onConfirm }) => {
  const data = useActionData();
  const navigate = useNavigate();

  const handleNo = () => {
    navigate('..')
  }

  return (
    <Modal onClose={onClose}>
      <Form method="post" className={styles.confirm}>
        {data && data.message &&
          <div className={styles.errors}>
            <p>{data.message}</p>
          </div>
        }
        <h2>{title ? title : 'Are you sure?'}</h2>
        <div className={styles.confirmations}>
          {onYes && <button className={styles.yesBtn} type="button" onClick={onYes}>Yes</button>}
          {!onYes && <button className={styles.yesBtn} type="submit">Yes</button>}
          <button className={styles.noBtn} type="button" onClick={onClose ? onClose : handleNo}>No</button>
        </div>
      </Form>
    </Modal>
  );
}

export default ConfirmModal;