import { useState, useEffect } from 'react';
import { Outlet, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import Modal from '../ui/Modal';

import styles from './SubmitFighterContent.module.css';

const SubmitFighterContent = () => {
  const data = useLoaderData();
  const navigate = useNavigate();
  const location = useLocation();
  const isConfirm = location.pathname.includes('/confirm');

  const [sport, setSport] = useState('NULL');
  const [selectedFighterId, setSelectedFighterId] = useState('NULL');
  const [filteredFighters, setFilteredFighters] = useState([]);

  useEffect(() => {
    if (sport === 'NULL') {
      setFilteredFighters([]);
    } else {
      const sportStatsMapping = {
        MMA: 'mma_stats',
        BOXING: 'boxing_stats',
        K1: 'k1_stats',
        MUAY_THAI: 'muay_thai_stats',
        GRAPPLING: 'grappling_stats',
      };

      const availableFighterIds = data.currentAvailable
        .filter(availableFighter => availableFighter.sport === sport)
        .map(availableFighter => availableFighter.fighter_id);

      const newFilteredFighters = data.fighters.filter(fighter =>
        fighter[sportStatsMapping[sport]] !== null &&
        !availableFighterIds.includes(fighter.id)
      );

      setFilteredFighters(newFilteredFighters);
    }
  }, [sport, data]);

  const handleSubmit = () => {
    if (selectedFighterId && selectedFighterId !== 'NULL') {
      navigate('confirm/' + sport.replace(/_/g, '-').toLowerCase() + '/' + selectedFighterId);
    }
  };

  return (
    <>
      <Outlet />
      {!isConfirm && (
        <Modal>
          <div className={styles.fighters}>
            <h2>Submit a Fighter</h2>
            {data && data.fighters.length > 0 ? (
              <>
                <select id="sport" name="sport" onChange={(event) => setSport(event.target.value)}>
                  <option value="NULL">Select a Sport</option>
                  {data.sports.map(sport => (
                    <option key={sport} value={sport}>{sport.replace(/_/g, ' ').toUpperCase()}</option>
                  ))}
                </select>
                <select onChange={(event) => setSelectedFighterId(event.target.value)} value={selectedFighterId}>
                  <option value="NULL">Select a Fighter</option>
                  {filteredFighters.map((fighter) => (
                    <option key={fighter.id} value={fighter.id}>
                      {`${fighter.first_name} ${fighter.last_name}`}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <p><strong>You have no available fighters for the sports on this event.</strong></p>
            )}
            <button onClick={handleSubmit} disabled={selectedFighterId === 'NULL'}>Submit</button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default SubmitFighterContent;
