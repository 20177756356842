import PromotionContent from '../components/explore/PromotionContent.jsx';

const Promotion = () => {
  return <PromotionContent />
}

export default Promotion;

export const loader = async ({ params }) => {
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const accountsResponse = await fetch(authUrl + '/api/v1/accounts/id/' + params.promotionId);
  const accountsResData = await accountsResponse.json();

  const eventsResponse = await fetch(authUrl + '/api/v1/events/promotion/' + params.promotionId);
  const eventsResData = await eventsResponse.json();

  return {...accountsResData, events: eventsResData};
}