import { NavLink, useLocation } from 'react-router-dom';
import { useSocket } from '../../context/SocketContext';

import styles from './DashboardNav.module.css';

const DashboardNav = () => {
  const location = useLocation();
  const isRequests = location.pathname.includes('/requests');
  const isNotifications = location.pathname.includes('/notifications');
  const { unreadNotificationsCount, pendingMatchCount } = useSocket();

  return <div className={styles.container}>
    <nav className={styles.navbar}>
      <div className={styles.navbarcontainer}>
        <ul className={styles.navbarleft}>
          <li className={styles.leftlink}>
            <NavLink
              to="/dashboard"
              className={
                isRequests ? undefined : isNotifications ? undefined : styles.active
              }
            >
              Your Page
            </NavLink>
          </li>
          {process.env.REACT_APP_SITE_PENDING !== 'true' && <li className={styles.leftlink}>
            <NavLink
              to="/dashboard/requests"
              className={
                isRequests ? styles.active : undefined
              }
            >
              Your Requests
            </NavLink>
            {pendingMatchCount > 0 && <span className={styles.unreadBadge}>{pendingMatchCount}</span>}
          </li>}
          <li className={styles.leftlink}>
            <NavLink
              to="/dashboard/notifications"
              className={
                isNotifications ? styles.active : undefined
              }
            >
              Your Notifications
            </NavLink>
            {unreadNotificationsCount > 0 && <span className={styles.unreadBadge}>{unreadNotificationsCount}</span>}
          </li>
        </ul>
      </div>
    </nav>
  </div>
}

export default DashboardNav;