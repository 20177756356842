import { useEffect } from 'react';
import { Form, Link, useActionData } from 'react-router-dom';

import Modal from '../ui/Modal';

import styles from './ChangePasswordForm.module.css';

const ChangeEmailContent = () => {
  const data = useActionData();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Modal>
      <Form method='post' className={styles.form}>
        <h2>Change Email</h2>
        {data && data.errors && (
          <div className={styles.errors}>
            <ul>
              {Object.values(data.errors).map((err) => (
                <li key={err}>{err}</li>
              ))}
            </ul>
          </div>
        )}
        {data && data.message &&
          <div className={styles.errors}>
            <p>{data.message}</p>
          </div>
        }
        <p>
          <label htmlFor="newEmail">New Email</label>
          <input id="newEmail" type="email" name="newEmail" placeholder="Enter your new email..." required />
        </p>
        <p>
          <label htmlFor="confirmNewEmail">Confirm New Email</label>
          <input id="confirmNewEmail" type="email" name="confirmNewEmail" placeholder="Confirm your new email..." required />
        </p>
        <p className={styles.actions}>
          <Link to="..">Cancel</Link>
          <button>Submit</button>
        </p>
      </Form>
    </Modal>
  );
}

export default ChangeEmailContent;