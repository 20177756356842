import { redirect } from 'react-router-dom';

import LogoutForm from '../components/users/LogoutForm';

const Logout = () => {
  return <LogoutForm /> 
}

export default Logout;

export const action = async() => {
  return redirect('/');
}
