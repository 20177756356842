import { Form, Link, useSubmit } from 'react-router-dom';
import { removeToken, tokenLoader } from '../../util/auth';
import { useSocket } from '../../context/SocketContext';
import Footer from "../ui/Footer";

import styles from './LogoutForm.module.css';

const LogoutForm = () => {
  const submit = useSubmit();
  const { connectWebSocket, clearNotifications } = useSocket();

  const handleLogout = async (event) => {
    event.preventDefault();
    const jwtToken = await tokenLoader();
    removeToken();
    connectWebSocket();
    clearNotifications();
    const fcmToken = localStorage.getItem('fcm_token');
    if (fcmToken) {
      try {
        const authUrl = process.env.REACT_APP_AUTH_URL;
        await fetch(`${authUrl}/api/v1/users/fcm/remove`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ fcm_token: fcmToken })
        });
      } catch (error) {
        console.error('Logout error:', error);
      }
    }
    submit(event.target, { method: 'post' });
  }

  return (
    <>
      <Form method="post" className={styles.form}>
        <h1>Confirm Logout</h1>
        <p>Are you sure you wish to logout?</p>
        <div className={styles.actions}>
          <Link to='/'>
            Cancel
          </Link>
          <button onClick={handleLogout}>
            Confirm
          </button>
        </div>
      </Form>
      <Footer />
    </>
  );
}

export default LogoutForm;