import { useState } from 'react';
import { useNavigate, Form, useSubmit, useParams } from 'react-router-dom';
import { useSocket } from '../../context/SocketContext';
import { tokenLoader, storeToken } from '../../util/auth';

import Modal from '../ui/Modal';

import styles from './ConfirmSwitchAccountModal.module.css';

const ConfirmSwitchAccountModal = () => {
  const submit = useSubmit();
  const navigate = useNavigate();
  const { connectWebSocket, clearNotifications, fetchUnreadCount, fetchUnreadNotificationsCount, fetchPendingMatchCount } = useSocket();
  const params = useParams();
  const [errorData, setErrorData] = useState(null);


  const handleNo = () => {
    navigate('..')
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = await tokenLoader();
    const formData = new FormData(event.target);
    const authData = {
      id: params.id,
      password: formData.get('password'),
    };
    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(authData)
    };
    const authUrl = process.env.REACT_APP_AUTH_URL;
    const authResponse = await fetch(authUrl + '/api/v1/users/switch-account', options);
  
    const resData = await authResponse.json();
  
    if (authResponse.ok) {
      const newToken = resData.token;
      storeToken(newToken);
      connectWebSocket();
      clearNotifications();
      fetchUnreadCount();
      fetchUnreadNotificationsCount();
      fetchPendingMatchCount();
      submit(event.currentTarget, { method: 'post' });
    } else {
      setErrorData(resData);
    }
  }

  return (
    <Modal onClose={handleNo}>
      <Form method="post" className={styles.confirm} onSubmit={handleSubmit}>
        <h2>Switch Account?</h2>
        {errorData && errorData.errors && (
          <div className={styles.errors}>
            <ul>
              {Object.values(errorData.errors).map((err) => (
                <li key={err}>{err}</li>
              ))}
            </ul>
          </div>
        )}
        {errorData && errorData.message &&
          <div className={styles.errors}>
            <p>{errorData.message}</p>
          </div>
        }
        <p>
          <label htmlFor="password"><strong>Confirm Password</strong></label>
          <input id="password" type="password" name="password" required />
        </p>
        <div className={styles.confirmations}>
          <button className={styles.yesBtn} type="submit">Yes</button>
          <button className={styles.noBtn} type="button" onClick={handleNo}>No</button>
        </div>
      </Form>
    </Modal>
  );
}

export default ConfirmSwitchAccountModal;