import { json, redirect } from 'react-router-dom';

import { checkAuthLoader, decodeToken } from '../util/auth';

import ChangeEmailForm from '../components/users/ChangeEmailForm';

const ChangePassword = () => {
  return <ChangeEmailForm />;
}

const validateForm = (emailData) => {
  let validationErrors = [];
  if (emailData.newEmail !== emailData.confirmNewEmail) {
    validationErrors.push('Email and confirm email do not match.')
  }
  return validationErrors;
}

export default ChangePassword;

export const action = async ({ request }) => {
  const data = await request.formData();
  const emailData = {
    newEmail: data.get('newEmail'),
    confirmNewEmail: data.get('confirmNewEmail')
  }

  const validationErrors = validateForm(emailData);
  if (validationErrors.length > 0) {
    return { errors: validationErrors }
  }

  const token = await checkAuthLoader();
  
  let bodyData = {
    role_user_id: decodeToken(token).activeRoleUserId,
    email: emailData.newEmail
  }

  const authUrl = process.env.REACT_APP_AUTH_URL
  const url = `${authUrl}/api/v1/users/email/update`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(bodyData)
  });

  const resData = await response.json();

  if (response.status === 401 || response.status === 403 || response.status === 404 || response.status === 409) {
    return resData;
  }

  if (!response.ok) {
    throw json({ message: resData.message }, { status: 500 });
  }

  return redirect('..')
}