import { useNavigate } from 'react-router-dom';

import styles from './UserCard.module.css';

const UserCard = ({ user }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(user.id);
  }

  return <div className={styles.card}>
    <h1>{user.organisation}</h1>
    <div className={styles.info}>
      {/* <p className={styles.email}>{user.username}</p> */}
      <p className={styles.phoneNumber}>{user.area_code + user.phone_number}</p>
      <p className={styles.orgType}>{user.role.includes('ADMIN') ? 'Admin' : user.role.includes('PROMOTION') ? 'Promotion' : 'Gym'}</p>
    </div>
    <button className={styles.actionButton} onClick={handleClick}>Action</button>
  </div>
}

export default UserCard;