import { checkPromotionOrAdminLoader } from '../util/auth';

import DirectoryResultsContent from '../components/directory/DirectoryResultsContent';

const DirectoryResult = () => {
  return <DirectoryResultsContent />
}

export default DirectoryResult;

export const loader = async ({ params }) => {
  const token = await checkPromotionOrAdminLoader();
  if (token instanceof Response) {
    return token;
  }
  const authUrl = process.env.REACT_APP_AUTH_URL
  const response = await fetch(authUrl + '/api/v1/directory/region/' + params.region + '/type/' + params.type, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });
  const resData = await response.json();
  return resData;
}