import { redirect } from 'react-router-dom';
import { checkPromotionLoader } from '../util/auth';

import FightMatcherContent from '../components/dashboard/FightMatcherContent';

const FightMatcher = () => {
  return <>
    <FightMatcherContent />
  </>
}

export default FightMatcher;

export const loader = async ({ params }) => {
  const token = await checkPromotionLoader();
  if (token instanceof Response) {
    return token;
  }
  const authUrl = process.env.REACT_APP_AUTH_URL;

  const eventsResponse = await fetch(authUrl + '/api/v1/events/fetch/' + params.eventId);
  const eventsResData = await eventsResponse.json();

  if (!eventsResData.sports.includes(params.sport.replace(/-/g, "_").toUpperCase())) {
    return redirect('/');
  }

  const fightersResponse = await fetch(authUrl + '/api/v1/fighters/explore/sport/' + params.sport.replace(/-/g, "_").toLowerCase() + '/event/' + params.eventId);
  const fightersResData = await fightersResponse.json();

  const accountResponse = await fetch(authUrl + '/api/v1/accounts/gyms');
  const accountResData = await accountResponse.json();

  const gymLookup = accountResData.reduce((acc, gym) => {
    acc[gym.user_id] = {
      organisation: gym.organisation,
      region: gym.region
    }
    return acc;
  }, {});

  const updatedFighters = fightersResData.map(fighter => ({
    ...fighter,
    gym_name: gymLookup[fighter.gym_id]?.organisation,
    region: gymLookup[fighter.gym_id]?.region
  }));

  const filteredAvailableFighters = eventsResData.available_fighters.filter(fighter => fighter.sport === params.sport.replace(/-/g, "_").toUpperCase());
  const availableFighterIds = filteredAvailableFighters.map(fighter => fighter.fighter_id);
  return { activeFighters: updatedFighters, submittedFighters: availableFighterIds };
}

export const action = async ({ params, request }) => {
  const data = await request.formData();
  const fighterA = data.get('fighterA');
  const fighterB = data.get('fighterB');
  const weightClass = data.get('weightClass');
  const catchWeight = data.get('catchWeight');
  const professional = data.get('professional') === 'PROFESSIONAL';
  const titleFight = data.get('titleFight');

  const token = await checkPromotionLoader();

  const authUrl = process.env.REACT_APP_AUTH_URL;

  const fighterAResponse = await fetch(authUrl + '/api/v1/fighters/fetch/' + fighterA);
  const fighterAResData = await fighterAResponse.json();
  const fighterAGymId = fighterAResData.gym_id;

  const fighterBResponse = await fetch(authUrl + '/api/v1/fighters/fetch/' + fighterB);
  const fighterBResData = await fighterBResponse.json();
  const fighterBGymId = fighterBResData.gym_id;

  const fightData = {
    fighter_a: {
      fighter_id: fighterA,
      gym_id: fighterAGymId
    },
    fighter_b: {
      fighter_id: fighterB,
      gym_id: fighterBGymId
    },
    sport: params.sport.replace(/-/g, "_").toUpperCase(),
    weight_class: catchWeight ? null : weightClass,
    catch_weight: catchWeight,
    professional: professional,
    title_fight: titleFight
  }

  let options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(fightData)
  };
  const eventsResponse = await fetch(authUrl + '/api/v1/events/request/' + params.eventId, options);
  const eventsResData = await eventsResponse.json();
  
  if (!eventsResponse.ok) {
    return eventsResData;
  }
  return redirect('/dashboard/requests');
}