import { json, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { tokenLoader } from '../util/auth';
import ConfirmModal from '../components/ui/ConfirmModal';

const ConfirmDeleteInbox = () => {
    const { refreshInboxData } = useOutletContext();
    const navigate = useNavigate();
    const { inboxId } = useParams();
    const handleYes = async () => {
        const token = await tokenLoader();
        let options = {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const authUrl = process.env.REACT_APP_AUTH_URL;
        const inboxResponse = await fetch(`${authUrl}/api/v1/inbox/delete/${inboxId}`, options);
        if (!inboxResponse.ok) {
            throw json({ message: 'An unexpected error has occurred' }, { status: 500 });
        }
        await refreshInboxData(true);
        navigate('/inbox', { replace: true });
    }

    return <ConfirmModal title="Delete Inbox?" onYes={handleYes} />;
};

export default ConfirmDeleteInbox;
