import { Outlet } from 'react-router-dom';

import ExploreContent from '../components/explore/ExploreContent';

const Explore = () => {
  return <>
    <Outlet />
    <ExploreContent />
  </>
}

export default Explore;

export const loader = async () => {
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const accountResponse = await fetch(authUrl + '/api/v1/accounts/gyms');
  const accountResData = await accountResponse.json();

  const eventsResponse = await fetch(authUrl + '/api/v1/events/explore');
  const eventsResData = await eventsResponse.json();

  const locations = accountResData
    .map((gym) => ({
      id: gym.user_id,
      name: gym.organisation,
      coordinates: gym.coordinates,
      address: gym.address,
      type: 'GYM',
    }))
    .concat(
      eventsResData.map((event) => ({
        id: event.id,
        name: event.name,
        coordinates: event.coordinates,
        address: event.address,
        date_time: event.date_time,
        type: 'EVENT',
      }))
    );

  return locations;
}