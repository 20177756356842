import { Link } from 'react-router-dom';

import PageContent from '../components/ui/PageContent';

const AccountPending = () => {
  return (
    <>
      <PageContent title='Account Pending Approval'>
        <h3>We will now review your details and approve your account within 24 hours.</h3>
        <h3>Once your account is active you will receive an email notification.</h3>
        <Link to="/">
          <p>Click here to go home.</p>
        </Link>
      </PageContent>
    </>
  );
}

export default AccountPending;