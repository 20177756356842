import { decodeToken, checkAuthLoader } from '../util/auth'

import DashboardContent from '../components/dashboard/DashboardContent';
import { redirect } from 'react-router-dom';

const Dashboard = () => {
  return <DashboardContent />
}

export default Dashboard;

export const loader = async () => {
  const token = await checkAuthLoader();
  if (token instanceof Response) {
    return token;
  }
  const decodedToken = decodeToken(token);
  const userId = decodedToken.activeRoleUserId;
  const role = decodedToken.authorities[0];
  const authUrl = process.env.REACT_APP_AUTH_URL;

  if (role === 'ROLE_ADMIN') {
    // const options = {
    //   headers: {
    //     Authorization: `Bearer ${token}`
    //   }
    // };
  
    // const authResponse = await fetch(authUrl + '/api/v1/users/list', options);
    // const authResData = await authResponse.json();
  
    // return authResData;
    return redirect('/admin');
  } else if (role === 'ROLE_PROMOTION') {
    const accountsResponse = await fetch(authUrl + '/api/v1/accounts/id/' + userId);
    const accountsResData = await accountsResponse.json();

    const eventsResponse = await fetch(authUrl + '/api/v1/events/promotion/' + userId);
    const eventsResData = await eventsResponse.json();

    return { ...accountsResData, events: eventsResData };
  } else if (role === 'ROLE_GYM') {
    const accountsResponse = await fetch(authUrl + '/api/v1/accounts/id/' + userId);
    const accountsResData = await accountsResponse.json();
  
    const fightersResponse = await fetch(authUrl + '/api/v1/fighters/gym/' + userId);
    const fightersResData = await fightersResponse.json();
  
    return {...accountsResData, fighters: fightersResData};
  }
}