import React, { useState } from 'react';
import { LoadScript } from '@react-google-maps/api';
import GoogleMapsContext from './GoogleMapsContext';

const libraries = ['places', 'geometry'];

const GoogleMapsProvider = ({ children }) => {
  const [isMapsApiLoaded, setMapsApiLoaded] = useState(false);

  return (
    <GoogleMapsContext.Provider value={{ isMapsApiLoaded, setMapsApiLoaded }}>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={libraries}
        onLoad={() => setMapsApiLoaded(true)}
      >
        {children}
      </LoadScript>
    </GoogleMapsContext.Provider>
  );
};

export default GoogleMapsProvider;