import { useState } from 'react';
import { useLocation, useRouteLoaderData, Link } from 'react-router-dom';
import { decodeToken } from '../../util/auth';
import moment from 'moment-timezone';

import styles from './FightCard.module.css';

const FightCard = ({ fight, eventId }) => {
  const token = useRouteLoaderData('root');
  const location = useLocation();
  const isRequestPage = location.pathname.includes('/requests');
  const isFighterPage = location.pathname.includes('/fighter');
  const [expanded, setExpanded] = useState(false);
  eventId = fight.event_id ? fight.event_id : eventId;

  const weightClassMap = {
    "straw_weight": "Strawweight",
    "light_fly_weight": "Light Flyweight",
    "fly_weight": "Flyweight",
    "super_fly_weight": "Super Flyweight",
    "bantam_weight": "Bantamweight",
    "super_bantam_weight": "Super Bantamweight",
    "feather_weight": "Featherweight",
    "super_feather_weight": "Super Featherweight",
    "light_weight": "Lightweight",
    "super_light_weight": "Super Lightweight",
    "welter_weight": "Welterweight",
    "super_welter_weight": "Super Welterweight",
    "middle_weight": "Middleweight",
    "super_middle_weight": "Super Middleweight",
    "light_heavy_weight": "Light Heavyweight",
    "cruiser_weight": "Cruiserweight",
    "heavy_weight": "Heavyweight"
  };

  let decodedToken = null;
  if (token) {
    decodedToken = decodeToken(token);
  }

  const handleClick = () => {
    setExpanded(!expanded);
  }

  const isFutureEvent = (dateString) => {
    const convertedTime = moment.utc(dateString).tz('Europe/London');
    const eventDate = convertedTime.toDate();
    eventDate.setHours(0, 0, 0, 0); // ignore time

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // ignore time

    return eventDate > currentDate;
  }

  const convertToTimezone = (utcDateTime) => {
    const convertedTime = moment.utc(utcDateTime).tz('Europe/London');
    return {
      date: convertedTime.format('DD/MM/yyyy'),
      time: convertedTime.format('HH:mm')
    };
  }

  const getFighterStats = () => {
    const stats = {
      fighter_a: {
        professional: null,
        amateur_record: null,
        pro_record: null,
      },
      fighter_b: {
        professional: null,
        amateur_record: null,
        pro_record: null,
      },
    };
    if (fight.sport === 'MMA') {
      stats.fighter_a.professional = fight.fighter_a.mma_stats.professional;
      stats.fighter_b.professional = fight.fighter_b.mma_stats.professional;
      stats.fighter_a.amateur_record = fight.fighter_a.mma_stats.amateur_record;
      stats.fighter_b.amateur_record = fight.fighter_b.mma_stats.amateur_record;
      stats.fighter_a.pro_record = fight.fighter_a.mma_stats.pro_record;
      stats.fighter_b.pro_record = fight.fighter_b.mma_stats.pro_record;
    } else if (fight.sport === 'BOXING') {
      stats.fighter_a.professional = fight.fighter_a.boxing_stats.professional;
      stats.fighter_b.professional = fight.fighter_b.boxing_stats.professional;
      stats.fighter_a.amateur_record = fight.fighter_a.boxing_stats.amateur_record;
      stats.fighter_b.amateur_record = fight.fighter_b.boxing_stats.amateur_record;
      stats.fighter_a.pro_record = fight.fighter_a.boxing_stats.pro_record;
      stats.fighter_b.pro_record = fight.fighter_b.boxing_stats.pro_record;
    } else if (fight.sport === 'K1') {
      stats.fighter_a.professional = fight.fighter_a.k1_stats.professional;
      stats.fighter_b.professional = fight.fighter_b.k1_stats.professional;
      stats.fighter_a.amateur_record = fight.fighter_a.k1_stats.amateur_record;
      stats.fighter_b.amateur_record = fight.fighter_b.k1_stats.amateur_record;
      stats.fighter_a.pro_record = fight.fighter_a.k1_stats.pro_record;
      stats.fighter_b.pro_record = fight.fighter_b.k1_stats.pro_record;
    } else if (fight.sport === 'MUAY_THAI') {
      stats.fighter_a.professional = fight.fighter_a.muay_thai_stats.professional;
      stats.fighter_b.professional = fight.fighter_b.muay_thai_stats.professional;
      stats.fighter_a.amateur_record = fight.fighter_a.muay_thai_stats.amateur_record;
      stats.fighter_b.amateur_record = fight.fighter_b.muay_thai_stats.amateur_record;
      stats.fighter_a.pro_record = fight.fighter_a.muay_thai_stats.pro_record;
      stats.fighter_b.pro_record = fight.fighter_b.muay_thai_stats.pro_record;
    } else if (fight.sport === 'GRAPPLING') {
      stats.fighter_a.professional = fight.fighter_a.grappling_stats.professional;
      stats.fighter_b.professional = fight.fighter_b.grappling_stats.professional;
      stats.fighter_a.amateur_record = fight.fighter_a.grappling_stats.amateur_record;
      stats.fighter_b.amateur_record = fight.fighter_b.grappling_stats.amateur_record;
      stats.fighter_a.pro_record = fight.fighter_a.grappling_stats.pro_record;
      stats.fighter_b.pro_record = fight.fighter_b.grappling_stats.pro_record;
    }
    return stats;
  }

  const fighterAPictureSrc = fight.fighter_a.image_url ? fight.fighter_a.image_url : process.env.PUBLIC_URL + '/authlogo.png';
  const fighterBPictureSrc = fight.fighter_b.image_url ? fight.fighter_b.image_url : process.env.PUBLIC_URL + '/authlogo.png';
  const championBeltSrc = process.env.PUBLIC_URL + '/championbelt.png';

  const stats = getFighterStats();
  const fightStart = convertToTimezone(fight.date_time);
  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={handleClick}>
        <div className={styles.firstSection}>
          <h2>{fight.fighter_a.first_name} {fight.fighter_a.last_name} vs {fight.fighter_b.first_name} {fight.fighter_b.last_name}</h2>
          {fight.title_fight &&
            <img className={styles.championBelt} src={championBeltSrc} alt="ChampionBelt" />
          }
        </div>
        <h3>Click to {expanded ? 'Hide' : 'Expand'}</h3>
      </div>
      {expanded && <div className={styles.body}>
        {(isRequestPage || isFighterPage) && <div className={styles.eventDetails}>
          <Link to={`/event/${fight.event_id}`}>
            <h1>{fight.event_name}</h1>
          </Link>
          <h4 className={styles.isTitle}>
            {fight.professional ? `PRO` : `AMATEUR`} {fight.title_fight ? `TITLE FIGHT` : `FIGHT`}</h4>
          <p className={styles.weightClass}>Sport: {fight.sport
            .replace(/_/g, ' ')
            .toUpperCase()} - {fight.weight_class ?
              weightClassMap[fight.weight_class] : `Catch Weight: ${fight.catch_weight}kg`}</p>
          <p className={styles.location}>{fight.address}</p>
          <p className={styles.dateTime}>Event Start: {fightStart.time} on {fightStart.date}</p>
        </div>}
        {isFutureEvent(fight.date_time) && decodedToken && decodedToken.authorities[0] === 'ROLE_PROMOTION' && !isFighterPage &&
          <div className={styles.promotionActions}>
            <Link to={`${fight.id}/delete`}>
              <button className={styles.actionButton}>{isRequestPage ? 'Withdraw Request' : 'Remove Match'}</button>
            </Link>
            <Link to={`${fight.id}/title/${fight.title_fight ? 'unset' : 'set'}`}>
              <button className={styles.actionButton}>{fight.title_fight ? 'Unset as Title Fight' : 'Set as Title Fight'}</button>
            </Link>
          </div>
        }
        {isRequestPage && decodedToken.authorities[0] === 'ROLE_GYM' &&
          <Link to={fight.id}>
            <button className={styles.actionButton}>Accept or Decline</button>
          </Link>
        }
        <div className={styles.fighters}>
          <div className={styles.fighterBlock}>
            <img className={styles.fighterPicture} src={fighterAPictureSrc} alt="FighterPicture" />
            <div className={styles.fighterInfo}>
              <Link to={`/fighter/${fight.fighter_a.id}`}>
                {fight.fighter_a.nickname && <h2 className={styles.fighterName}>{`${fight.fighter_a.first_name} '${fight.fighter_a.nickname}' ${fight.fighter_a.last_name}`}</h2>}
                {!fight.fighter_a.nickname && <h2 className={styles.fighterName}>{`${fight.fighter_a.first_name} ${fight.fighter_a.last_name}`}</h2>}
              </Link>
              {isRequestPage && fight.fighter_a.rejected && <h4 className={styles.rejected}>
                DECLINED
              </h4>}
              {isRequestPage && !fight.fighter_a.rejected && <h4 className={`${fight.fighter_a.accepted ? styles.accepted : styles.pending}`}>
                {fight.fighter_a.accepted ? 'ACCEPTED' : 'PENDING'}
              </h4>}
              {stats.fighter_a.professional && <p className={styles.proRecord}>Pro Record: {stats.fighter_a.pro_record.wins} - {stats.fighter_a.pro_record.losses} - {stats.fighter_a.pro_record.draws}</p>}
              <p className={styles.amateurRecord}>Amateur Record: {stats.fighter_a.amateur_record.wins} - {stats.fighter_a.amateur_record.losses} - {stats.fighter_a.amateur_record.draws}</p>
              <Link to={`/gym/${fight.fighter_a.gym_id}`}>
                <p>
                  {`Gym: ${fight.fighter_a.gym_name}`}
                </p>
              </Link>
              {!isRequestPage && isFutureEvent(fight.date_time) && decodedToken && decodedToken.activeRoleUserId && decodedToken.activeRoleUserId === fight.fighter_a.gym_id &&
                <Link to={`/event/${eventId}/pullout-fighter/${fight.id}`}>
                  <button className={styles.pulloutButton}>Pullout Fighter</button>
                </Link>}
            </div>
          </div>
          <br />
          <div className={styles.fighterBlock}>
            <img className={styles.fighterPicture} src={fighterBPictureSrc} alt="FighterPicture" />
            <div className={styles.fighterInfo}>
              <Link to={`/fighter/${fight.fighter_b.id}`}>
                {fight.fighter_b.nickname && <h2 className={styles.fighterName}>{`${fight.fighter_b.first_name} '${fight.fighter_b.nickname}' ${fight.fighter_b.last_name}`}</h2>}
                {!fight.fighter_b.nickname && <h2 className={styles.fighterName}>{`${fight.fighter_b.first_name} ${fight.fighter_b.last_name}`}</h2>}
              </Link>
              {isRequestPage && fight.fighter_b.rejected && <h4 className={styles.rejected}>
                DECLINED
              </h4>}
              {isRequestPage && !fight.fighter_b.rejected && <h4 className={`${fight.fighter_b.accepted ? styles.accepted : styles.pending}`}>
                {fight.fighter_b.accepted ? 'ACCEPTED' : 'PENDING'}
              </h4>}
              {stats.fighter_b.professional && <p className={styles.proRecord}>Pro Record: {stats.fighter_b.pro_record.wins} - {stats.fighter_b.pro_record.losses} - {stats.fighter_b.pro_record.draws}</p>}
              <p className={styles.amateurRecord}>Amateur Record: {stats.fighter_b.amateur_record.wins} - {stats.fighter_b.amateur_record.losses} - {stats.fighter_b.amateur_record.draws}</p>
              <Link to={`/gym/${fight.fighter_b.gym_id}`}>
                <p>
                  {`Gym: ${fight.fighter_b.gym_name}`}
                </p>
              </Link>
              {!isRequestPage && isFutureEvent(fight.date_time) && decodedToken && decodedToken.activeRoleUserId === fight.fighter_b.gym_id &&
                <Link to={`/event/${eventId}/pullout-fighter/${fight.id}`}>
                  <button className={styles.pulloutButton}>Pullout Fighter</button>
                </Link>}
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  )
}

export default FightCard;