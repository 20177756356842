import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import styles from './ImageDrop.module.css';

const ImageDrop = ({ onDrop }) => {
  const handleDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      onDrop(acceptedFiles[0]);
    }
  }, [onDrop]);

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg']
    },
    maxFiles: 1
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path}
      <ul>
        {errors.map((e) => {
          if (e.code === 'file-invalid-type') {
            return <li key={e.code}>File must be an image (JPEG or PNG)</li>;
          } else if (e.code === 'too-many-files') {
            return <li key={e.code}>Only 1 image can be uploaded</li>;
          } else {
            return <li key={e.code}>Unexpected error: {e.message}</li>
          }
        })}
      </ul>
    </li>
  ));

  return (
    <>
      <div {...getRootProps()} className={styles.dropzone}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the image here...</p> :
            <p className={styles.uploadLink}>Click to select an image or drop one here...</p>
        }
      </div>
      {fileRejections.length > 0 && (
        <aside>
          <h4>Rejected files</h4>
          <ul className={styles.errors}>{fileRejectionItems}</ul>
        </aside>
      )}
    </>
  );
}

export default ImageDrop;