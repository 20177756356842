import { json, redirect } from 'react-router-dom';

import { checkAuthLoader, decodeToken } from '../util/auth';

import ChangePasswordForm from '../components/users/ChangePasswordForm';

const ChangePassword = () => {
  return <ChangePasswordForm />;
}

const validateForm = (passwordData) => {
  let validationErrors = [];
  if (passwordData.password.length < 8) {
    validationErrors.push('Password must be at least 8 characters.');
  }
  if (!/[A-Z]/.test(passwordData.password)) {
    validationErrors.push('Password must contain at least 1 upper case letter.');
  }
  if (!/[a-z]/.test(passwordData.password)) {
    validationErrors.push('Password must contain at least 1 lower case letter.');
  }
  if (passwordData.password !== passwordData.confirmPassword) {
    validationErrors.push('Password and confirm password do not match.')
  }
  return validationErrors;
}

export default ChangePassword;

export const action = async ({ request }) => {
  const data = await request.formData();
  const passwordData = {
    password: data.get('password'),
    confirmPassword: data.get('confirmPassword')
  }

  const validationErrors = validateForm(passwordData);
  if (validationErrors.length > 0) {
    return { errors: validationErrors }
  }

  const token = await checkAuthLoader();

  let bodyData = {
    role_user_id: decodeToken(token).activeRoleUserId,
    password: passwordData.password
  }

  const authUrl = process.env.REACT_APP_AUTH_URL
  const url = `${authUrl}/api/v1/users/password/change`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(bodyData)
  });

  const resData = await response.json();

  if (response.status === 401 || response.status === 403 || response.status === 404 || response.status === 409) {
    return resData;
  }

  if (!response.ok) {
    throw json({ message: resData.message }, { status: 500 });
  }

  return redirect('..')
}