import { Link } from 'react-router-dom';

import styles from './NotificationCard.module.css';

const NotificationCard = ({ notification }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year} at ${hours}:${minutes}`;
  };

  return (
    <div className={styles.container}>
      <h2>{notification.subject}</h2>
      <p>{notification.body}</p>
      <h4>Received: {formatDate(notification.timestamp)}</h4>
      <div className={styles.buttons}>
        <Link to={`/${notification.action_link}`}>
          <button className={styles.actionButton}>View More</button>
        </Link>
        <Link to={`read/${notification.id}`}>
          <button className={styles.actionButton}>Mark Read</button>
        </Link>
      </div>
    </div>
  )
}

export default NotificationCard;