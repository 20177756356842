import PreviewMarkerContent from '../components/explore/PreviewMarkerContent';

const PreviewMarker = () => {
  return <PreviewMarkerContent />
}

export const loader = async ({ params }) => {
  const authUrl = process.env.REACT_APP_AUTH_URL;
  let results;
  if (params.type === 'gym') {
    const accountsResponse = await fetch(authUrl + '/api/v1/accounts/id/' + params.id);
    results = await accountsResponse.json();
  } else if (params.type === 'event') {
    const eventsResponse = await fetch(authUrl + '/api/v1/events/fetch/' + params.id);
    const eventsResData = await eventsResponse.json();
    const accountsResponse = await fetch(authUrl + '/api/v1/accounts/id/' + eventsResData.promotion_id);
    const accountsResData = await accountsResponse.json();
    results = { ...eventsResData, promotion: accountsResData }
  }
  return results
}

export default PreviewMarker;