import React from 'react';
import styles from './InboxTile.module.css';
import moment from 'moment';

const convertToTimezone = (utcDateTime) => {
    const convertedTime = moment.utc(utcDateTime).tz('Europe/London');
    return convertedTime.format('DD/MM/yyyy HH:mm');
}

const InboxTile = ({ inbox, userId, isSelected, onSelect }) => {
    const getDisplayName = () => {
        if (inbox.inbox_name) return inbox.inbox_name;
        if (inbox.group) return inbox.participants.slice(0, 3).map(p => p.participant_name).join(', ') + (inbox.participants.length > 3 ? ', ...' : '');
        return inbox.participants.find(p => p.participant_id !== userId)?.participant_name || "Unknown";
    };

    const getDisplayImage = () => {
        const defaultImage = `${process.env.PUBLIC_URL}/defaultpicture.png?timestamp=${new Date().getTime()}`;
        if (inbox.inbox_image_url) return `${inbox.inbox_image_url}?timestamp=${new Date().getTime()}`;
        if (inbox.group) return defaultImage;
        const otherParticipant = inbox.participants.find(p => p.participant_id !== userId);
        return otherParticipant?.participant_image_url ? `${otherParticipant.participant_image_url}?timestamp=${new Date().getTime()}` : defaultImage;
    };

    const isUnread = inbox.latest_message && inbox.last_read_timestamp < inbox.latest_message.timestamp;

    return (
        <div className={`${styles.inboxTile} ${isSelected ? styles.selectedInbox : ''} ${isUnread ? styles.unreadInbox : ''}`} onClick={() => onSelect(inbox.id)}>
            <div className={styles.avatarContainer}>
                {getDisplayImage() ? (
                    <img src={getDisplayImage()} alt={getDisplayName()} className={styles.avatar} />
                ) : (
                    <div className={styles.defaultAvatar}>{getDisplayName().charAt(0)}</div>
                )}
            </div>
            <div className={styles.inboxInfo}>
                <div className={styles.inboxNameContainer}>
                    <h3 className={styles.inboxName}>{getDisplayName()}</h3>
                    {inbox.special && <span className={styles.specialBadge}>Event</span>}
                    {inbox.group && !inbox.special && <span className={styles.groupBadge}>Group</span>}
                </div>
                <div className={`${styles.lastMessageContainer} ${isUnread ? styles.unreadMessage : ''}`}>
                    {inbox.latest_message ? (
                        <>
                            {isUnread && <span className={styles.unreadDot}></span>}
                            <span className={`${styles.senderName} ${isUnread ? styles.unreadMessage : ''}`}>
                                {inbox.latest_message.sender_id === userId ? 'You: ' : `${inbox.latest_message.sender_name}: `}
                            </span>
                            <span className={`${styles.messageContent} ${isUnread ? styles.unreadMessage : ''}`}>
                                {inbox.latest_message.message}
                            </span>
                            <span className={styles.messageTimestamp}>
                                {convertToTimezone(inbox.latest_message.timestamp)}
                            </span>
                        </>
                    ) : (
                        <>
                            <span className={styles.messageContent}>No messages yet</span>
                            <span className={styles.messageTimestamp}>
                                {convertToTimezone(inbox.sorting_timestamp)}
                            </span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InboxTile;