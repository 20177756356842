import { redirect, json } from 'react-router-dom';
import { checkNoAuthLoader } from '../util/auth';

const ConfirmEmailAction = () => {
  return <p>Redirecting...</p>
}

export default ConfirmEmailAction;

export const loader = async ({ params }) => {
  const isAuthed = await checkNoAuthLoader();
  if (isAuthed instanceof Response) {
    return isAuthed;
  }
  const authUrl = process.env.REACT_APP_AUTH_URL
  const response = await fetch(authUrl + '/api/v1/auth/confirm-email/' + params.id, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const resData = await response.json();

  if (response.status === 401) {
    return redirect('/login');
  }

  if (!response.ok) {
    throw json({ message: resData.message }, { status: 500 });
  }

  return redirect(resData.redirect_url);
}