import React from 'react';
import InboxContent from '../components/inbox/InboxContent';
import { checkAuthLoader } from '../util/auth';

const InboxPage = () => {
  return (
      <InboxContent />
  );
};

export default InboxPage;

export async function loader() {
  const token = await checkAuthLoader();
  if (token instanceof Response) {
    return token;
  }
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const response = await fetch(`${authUrl}/api/v1/inbox/retrieve`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch inbox data');
  }

  return response.json();
}