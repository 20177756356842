import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { tokenLoader, decodeToken } from '../util/auth';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const [stompClient, setStompClient] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [lastMessage, setLastMessage] = useState(null);
    const [lastDeletedMessage, setLastDeletedMessage] = useState(null);
    const clientRef = useRef(null);
    const audioRef = useRef(new Audio(process.env.PUBLIC_URL + '/notification.mp3'));
    const [unreadCount, setUnreadCount] = useState(0);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    const [pendingMatchCount, setPendingMatchCount] = useState(0);
    const subscriptionsRef = useRef([]);
    const subscriptionTopicsRef = useRef({});
    const reconnectTimeoutRef = useRef(null);

    const removeNotification = useCallback((notificationId) => {
        setNotifications(prev => prev.filter(notification => notification.id !== notificationId));
    }, []);

    const handleNotification = useCallback(async (notification) => {
        const notificationId = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
        setNotifications(prev => {
            audioRef.current.play().catch(e => console.error("Error playing audio:", e));
            return [...prev, {
                id: notificationId,
                subject: notification.subject,
                body: notification.body,
                link: notification.link
            }];
        });

        // Set a timeout to remove the notification after 5 seconds
        setTimeout(() => removeNotification(notificationId), 5000);
    }, [removeNotification]);

    const handleNewMessage = useCallback(async (newMessage) => {
        const currentPath = window.location.pathname;
        const token = await tokenLoader();
        if (!currentPath.includes('/inbox')) {
            if (decodeToken(token).activeRoleUserId !== newMessage.sender_id) {
                const notificationId = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
                if (newMessage.loud) {
                    setNotifications(prev => {
                        // Check if the notification already exists
                        if (!prev.some(notif => notif.inboxId === newMessage.inbox_id && notif.message === newMessage.message)) {
                            // Play the notification sound
                            audioRef.current.play().catch(e => console.error("Error playing audio:", e));

                            // Set a timeout to remove the notification after 5 seconds
                            setTimeout(() => removeNotification(notificationId), 5000);

                            return [...prev, {
                                id: notificationId,
                                subject: 'New message from ' + newMessage.sender_name,
                                body: newMessage.message,
                                link: `/inbox?selectedInbox=${newMessage.inbox_id}`
                            }];
                        }
                        return prev;
                    });
                }
                setUnreadCount(prevCount => prevCount + 1);
                setLastMessage(null);
            }
        } else {
            setLastMessage(prevMessage => {
                // Only update if it's a new message
                if (!prevMessage || prevMessage.id !== newMessage.id) {
                    if (token && newMessage.sender_id !== decodeToken(token).activeRoleUserId && newMessage.loud) {
                        audioRef.current.play().catch(e => console.error("Error playing audio:", e));
                    }
                    return newMessage;
                }
                return prevMessage;
            });
        }
    }, [removeNotification]);

    const handleDeleteMessage = useCallback((deleteMessage) => {
        setLastMessage(null);
        setLastDeletedMessage(deleteMessage);
    }, []);

    const hasActiveSubscription = useCallback(async () => {
        if (!clientRef.current) {
            return false;
        }
        return clientRef.current._stompHandler._subscriptions === 3;
    }, []);

    const connectWebSocket = useCallback(async () => {
        const token = await tokenLoader();
        if (token) {
            const decodedToken = decodeToken(token);

            // Disconnect existing client if it exists
            if (clientRef.current) {
                clientRef.current.deactivate();
            }

            clientRef.current = new Client({
                webSocketFactory: () => new SockJS(process.env.REACT_APP_WS_URL),
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000,
            });

            clientRef.current.onConnect = () => {
                setStompClient(clientRef.current);

                // Unsubscribe from previous subscriptions
                subscriptionsRef.current.forEach(subscription => subscription.unsubscribe());
                subscriptionsRef.current = [];
                subscriptionTopicsRef.current = {};

                // Add a small delay to ensure the connection is fully established
                setTimeout(() => {
                    if (clientRef.current.connected) {
                        const newMessageSub = clientRef.current.subscribe(`/topic/new-message/user/${decodedToken.activeRoleUserId}`, (messageOutput) => {
                            const newMessage = JSON.parse(messageOutput.body);
                            handleNewMessage(newMessage);
                        });
                        subscriptionTopicsRef.current[newMessageSub.id] = `/topic/new-message/user/${decodedToken.activeRoleUserId}`;

                        const deleteMessageSub = clientRef.current.subscribe(`/topic/delete-message/user/${decodedToken.activeRoleUserId}`, (messageOutput) => {
                            const deleteMessage = JSON.parse(messageOutput.body);
                            handleDeleteMessage(deleteMessage);
                        });
                        subscriptionTopicsRef.current[deleteMessageSub.id] = `/topic/delete-message/user/${decodedToken.activeRoleUserId}`;

                        const notificationSub = clientRef.current.subscribe(`/topic/notification/user/${decodedToken.activeRoleUserId}`, (messageOutput) => {
                            const notification = JSON.parse(messageOutput.body);
                            handleNotification(notification);
                        });
                        subscriptionTopicsRef.current[notificationSub.id] = `/topic/notification/user/${decodedToken.activeRoleUserId}`;


                        // Store new subscriptions
                        subscriptionsRef.current = [newMessageSub, deleteMessageSub, notificationSub];
                    } else {
                        console.error('STOMP connection not established');
                    }
                }, 100); // 100ms delay

                console.log('WebSocket connected');
                if (reconnectTimeoutRef.current) {
                    clearTimeout(reconnectTimeoutRef.current);
                    reconnectTimeoutRef.current = null;
                }
            };

            clientRef.current.onStompError = (frame) => {
                console.error('Broker reported error: ' + frame.headers['message']);
                console.error('Additional details: ' + frame.body);
            };

            clientRef.current.onWebSocketClose = () => {
                console.log('WebSocket closed');
                console.log('Attempting to reconnect WebSocket');
                if (reconnectTimeoutRef.current) {
                    clearTimeout(reconnectTimeoutRef.current);
                }
                reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
            }

            clientRef.current.activate();
        } else {
            if (clientRef.current) {
                clientRef.current.deactivate();
                clientRef.current = null;
            }
        }
    }, [handleNewMessage, handleDeleteMessage, handleNotification]);

    const reconnectWebSocket = useCallback(async () => {
        if (!hasActiveSubscription()) {
            console.log('Establishing new connection.');
            await connectWebSocket();
        } else {
            console.log('Already connected.');
        }
    }, [connectWebSocket, hasActiveSubscription])

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (clientRef.current) {
                clientRef.current.deactivate();
                clientRef.current = null;
            }
        };
    }, [connectWebSocket]);

    const clearNotifications = () => {
        setNotifications([]);
    };

    const fetchUnreadCount = async () => {
        const token = await tokenLoader();
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/api/v1/inbox/unread/count`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                if (data.unread_count !== undefined) {
                    setUnreadCount(data.unread_count);
                } else {
                    console.error('Unexpected data format:', data);
                }
            } catch (error) {
                console.error('Error fetching initial unread messages count:', error);
            }
        } else {
            setUnreadCount(0);
            console.log('No token available for fetching unread messages count');
        }
    };

    const fetchUnreadNotificationsCount = async () => {
        const token = await tokenLoader();
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/api/v1/notifications/unread/count`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                if (data.unread_count !== undefined) {
                    setUnreadNotificationsCount(data.unread_count);
                } else {
                    console.error('Unexpected data format:', data);
                }
            } catch (error) {
                console.error('Error fetching initial unread notifications count:', error);
            }
        } else {
            setUnreadNotificationsCount(0);
            console.log('No token available for fetching unread notifications count');
        }
    }

    const fetchPendingMatchCount = async () => {
        const token = await tokenLoader();
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/api/v1/events/pending/count`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                if (data.unread_count !== undefined) {
                    setPendingMatchCount(data.unread_count);
                } else {
                    console.error('Unexpected data format:', data);
                }
            } catch (error) {
                console.error('Error fetching initial pending match count:', error);
            }
        } else {
            setPendingMatchCount(0);
            console.log('No token available for fetching pending match count');
        }
    }

    useEffect(() => {
        fetchUnreadCount();
        fetchUnreadNotificationsCount();
        fetchPendingMatchCount();
    }, []);

    const contextValue = {
        stompClient,
        lastMessage,
        lastDeletedMessage,
        notifications,
        clearNotifications,
        removeNotification,
        audioRef,
        unreadCount,
        unreadNotificationsCount,
        pendingMatchCount,
        fetchUnreadCount,
        fetchUnreadNotificationsCount,
        fetchPendingMatchCount,
        connectWebSocket,
        reconnectWebSocket
    };

    return (
        <SocketContext.Provider value={contextValue}>
            {children}
        </SocketContext.Provider>
    );
};
