import { decodeToken, checkGymLoader } from '../util/auth';

import SubmitFighterContent from '../components/dashboard/SubmitFighterContent';

const SubmitFighter = () => {
  return <SubmitFighterContent />
}

export default SubmitFighter;

export const loader = async ({ params }) => {
  const token = await checkGymLoader();
  if (token instanceof Response) {
    return token;
  }
  const userId = decodeToken(token).activeRoleUserId;

  const authUrl = process.env.REACT_APP_AUTH_URL;
  const fightersResponse = await fetch(authUrl + '/api/v1/fighters/gym/' + userId);
  const fightersResData = await fightersResponse.json();

  const eventsResponse = await fetch(authUrl + '/api/v1/events/fetch/' + params.eventId);
  const eventsResData = await eventsResponse.json();

  const lockedInFighterIds = eventsResData.fights.flatMap(fight => [fight.fighter_a.fighter_id, fight.fighter_b.fighter_id]);
  const filteredFighters = fightersResData.filter(fighter => !lockedInFighterIds.includes(fighter.id) && fighter.active);

  return {fighters: filteredFighters, currentAvailable: eventsResData.available_fighters, sports: eventsResData.sports};
}