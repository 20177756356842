import { Outlet } from "react-router-dom";

import MainNavigation from '../components/ui/MainNavigation';
import NotificationsCard from '../components/ui/NotificationsCard';

const RootLayout = () => {
  return (
    <>
      <MainNavigation />
      <NotificationsCard />
      <main>
          <Outlet />
      </main>
    </>
  );
}

export default RootLayout;

