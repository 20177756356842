import { redirect, json } from 'react-router-dom';

import { checkAuthLoader } from '../util/auth';

import ConfirmModal from '../components/ui/ConfirmModal';

const ConfirmReadNotifications = () => {
  return <ConfirmModal title="Mark as Read?" />
}

export default ConfirmReadNotifications;

export const action = async ({ params }) => {
  const token = await checkAuthLoader();
  let options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const response = await fetch(authUrl + '/api/v1/notifications/read/' + params.id, options);
  const resData = await response.json();

  if (!response.ok) {
    throw json({ message: resData.message }, { status: 500 });
  }

  return redirect('..');
}