import { Outlet } from 'react-router-dom';
import { checkPromotionOrAdminLoader } from '../util/auth';
import DirectoryTypesContent from '../components/directory/DirectoryTypesContent';

const Directory = () => {
  return <>
    <Outlet />
    <DirectoryTypesContent />
  </>
}

export const loader = async () => {
  const token = await checkPromotionOrAdminLoader();
  if (token instanceof Response) {
    return token;
  }
  const authUrl = process.env.REACT_APP_AUTH_URL
  const response = await fetch(authUrl + '/api/v1/directory/explore', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });
  const resData = await response.json();
  const grouped = {};

  // Group objects by type
  resData.forEach(item => {
    const { type } = item;
    if (!grouped[type]) {
      grouped[type] = [];
    }
    grouped[type].push(item);
  });

  // Create an object to hold counts
  const counts = {};
  for (let type in grouped) {
    counts[type] = grouped[type].length;
  }

  return counts;
}

export default Directory;