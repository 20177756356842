import { checkPromotionLoader } from '../util/auth';

import FightMatcherSportSelectContent from '../components/dashboard/FightMatcherSportSelectContent';

const FightMatcherSportSelect = () => {
  return <FightMatcherSportSelectContent />
}

export default FightMatcherSportSelect;

export const loader = async ({ params }) => {
  const token = await checkPromotionLoader();
  if (token instanceof Response) {
    return token;
  }
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const eventsResponse = await fetch(authUrl + '/api/v1/events/fetch/' + params.eventId);
  const eventsResData = await eventsResponse.json();

  return eventsResData.sports;
}