import { useNavigate, useLocation } from 'react-router-dom';

import styles from './OptionCard.module.css';

const RegionCard = ({ title, link, count }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isRegionPage = location.pathname.includes("region");

  const convertTitle = (titleCasedString) => {
    return titleCasedString
      .split(' ') 
      .map(part => part.toLowerCase())  
      .join('-'); 
  }

  const handleClick = () => {
    navigate(link + '/' + convertTitle(title));
  }

  return <div className={styles.card}>
    <h1>{title}</h1>
    {isRegionPage && <button className={styles.actionButton} disabled={!count} onClick={handleClick}>{count ? `View ${count} Results` : '0 Results'}</button>}
    {!isRegionPage && <button className={styles.actionButton} onClick={handleClick}>Select</button>}
  </div>
}

export default RegionCard;