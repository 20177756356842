import { json, redirect } from 'react-router-dom';
import { checkAuthLoader } from '../util/auth';

import ConfirmModal from '../components/ui/ConfirmModal';

const ConfirmMatchStatus = () => {
 return <ConfirmModal />
}

export default ConfirmMatchStatus;

export const action = async ({ params }) => {
  const token = await checkAuthLoader();
  let options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const authUrl = process.env.REACT_APP_AUTH_URL
  let url = authUrl + '/api/v1/events/' + params.status + '/' + params.fightId;
  const response = await fetch(url, options);
  if (!response.ok) {
    throw json({ message: 'An unexpected error has occurred' }, { status: 500 });
  }
  return redirect('../..');
}