import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../context/SocketContext';
import styles from './NotificationsCard.module.css';

const NotificationsCard = () => {
    const navigate = useNavigate();
    const { notifications, clearNotifications, removeNotification } = useSocket();

    return (
        notifications.length > 0 && (
        <div className={styles.card}>
            <div className={styles.header}>
                <h3>New Notifications ({notifications.length})</h3>
                <button className={styles.clearButton} onClick={clearNotifications}>Clear All</button>
            </div>
            <ul>
                {notifications.slice().reverse().map((notification, index) => (
                    <li key={notification.id || index}>
                        <div className={styles.notification}
                            onClick={() => {
                                navigate(notification.link);
                                removeNotification(notification.id);
                            }}
                        >
                           
                            <p className={styles.subject}><span className={styles.unreadDot}></span>{notification.subject}</p>
                            <p className={styles.body}>{notification.body}</p>
                        </div>
                    </li>
                ))}
                </ul>
            </div>
        )
    );
};

export default NotificationsCard;