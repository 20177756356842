import { json, redirect } from 'react-router-dom';

import { checkAdminLoader } from '../util/auth';

import ConfirmModal from '../components/ui/ConfirmModal';

const ConfirmUserStatus = () => {
  return <ConfirmModal />
}

export default ConfirmUserStatus;

export const action = async ({ params }) => {
  const token = await checkAdminLoader();
  let options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const authUrl = process.env.REACT_APP_AUTH_URL
  let url = authUrl + '/api/v1/users/';
  if (params.status === 'gym' || params.status === 'promotion') {
    url += 'role/update';
    options.headers = {
      ...options.headers,
      'Content-Type': 'application/json'
    }
    options = {
      ...options,
      body: JSON.stringify({
        role_user_id: params.userId,
        role: params.status === 'gym' ? 'ROLE_GYM' : 'ROLE_PROMOTION'
      })
    }
  } else {
    url += params.status + '/' + params.userId;
  }
  const response = await fetch(url, options);
  if (!response.ok) {
    throw json({ message: 'An unexpected error has occurred' }, { status: 500 });
  }
  return redirect('..');
}