import { useEffect } from 'react';
import { useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { decodeToken } from '../../util/auth'

import FightCard from '../explore/FightCard';
import DashboardNav from './DashboardNav';

import styles from './RequestsContent.module.css';

const RequestsContent = () => {
  const requests = useLoaderData();
  const token = useRouteLoaderData('root');
  const decodedToken = decodeToken(token);
  const role = decodedToken.authorities[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const heading = role === "ROLE_GYM"
    ? "Your pending match requests"
    : "Your outgoing match requests";

  const requestCountText = `${requests.length} Request${requests.length === 1 ? '' : 's'}`;

  let actionStatement = '';
  if (role === "ROLE_GYM") {
    actionStatement = 'Click a match to view details and accept or decline';
  } else if (role === "ROLE_PROMOTION") {
    actionStatement = 'Click a match to view details';
  }

  return (
    <>
      <DashboardNav />
      <div className={styles.container}>
        <h1>{heading}</h1>
        <h3>{requestCountText}</h3>
        {requests.length > 0 && <h3>{actionStatement}</h3>}
        <ul className={styles.fightsList}>
          {requests.map(fight => (
            <li key={fight.id} className={styles.fightsListItem}>
              <FightCard fight={fight} />
            </li>
          ))}
        </ul>
      </div></>
  )
}

export default RequestsContent;

