import { useNavigate } from 'react-router-dom';

import styles from './AccountCard.module.css';

const AccountCard = ({ account, tokenSub }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('switch/' + account.id);
  }

  return <div className={styles.card}>
    <h1>{account.organisation}</h1>
    <div className={styles.info}>
      <p className={styles.email}>{account.contact_email}</p>
      <p className={styles.phoneNumber}>{account.phone_number}</p>
      <p className={styles.orgType}>{account.role.includes('ADMIN') ? 'Admin' : account.role.includes('PROMOTION') ? 'Promotion' : 'Gym'}</p>
    </div>
    {tokenSub !== account.id && <button className={styles.switchButton} onClick={handleClick}>Switch Account</button>}
    {tokenSub === account.id && <button className={styles.activeButton} disabled={true}>Active</button>}
  </div>
}

export default AccountCard;