import { json } from 'react-router-dom';
import { checkGymOrAdminLoader } from '../util/auth';
import { redirect } from 'react-router-dom';

import FighterForm from "../components/dashboard/FighterForm";

const EditFighter = () => {
  return <FighterForm />
}

export default EditFighter;

const tapologyValidUrls = [
  "https://tapology.com/",
  "https://www.tapology.com/"
];

const validateForm = (fighterData, fighterStatsData) => {
  let validationErrors = [];

  // Check if at least one sport is selected
  const isSportSelected = Object.values(fighterStatsData).some(stat => stat !== null);
  if (!isSportSelected) {
    validationErrors.push('At least one sport must be selected.');
  }
  if (fighterData.tapology_url && !tapologyValidUrls.some(url => fighterData.tapology_url.startsWith(url))) {
    validationErrors.push("Tapology link is invalid.");
  }

  // Validate min and max catch weight ranges
  Object.keys(fighterStatsData).forEach(statKey => {
    const stats = fighterStatsData[statKey];
    if (stats && stats.catch_weight_range) {
      const { min, max } = stats.catch_weight_range;
      if (min > max) {
        validationErrors.push(`Catch weight range is invalid for ${statKey}: min (${min}) cannot be greater than max (${max}).`);
      }
    }
  });

  return validationErrors;
}

export const loader = async ({ params }) => {
  const token = await checkGymOrAdminLoader();
  if (token instanceof Response) {
    return token;
  }
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const fightersResponse = await fetch(authUrl + '/api/v1/fighters/fetch/' + params.fighterId);
  const fightersResData = await fightersResponse.json();

  return fightersResData;
}

export const action = async ({ request, params }) => {
  const data = await request.formData();

  const sportsStats = {
    'MMA': 'mma_stats',
    'Boxing': 'boxing_stats',
    'K1': 'k1_stats',
    'MuayThai': 'muay_thai_stats',
    'Grappling': 'grappling_stats'
  };

  const fighterStatsData = {
    mma_stats: null,
    boxing_stats: null,
    k1_stats: null,
    muay_thai_stats: null,
    grappling_stats: null
  };

  Object.keys(sportsStats).forEach(sport => {
    if (data.get(`${sport}_participation`) === 'yes') {
      fighterStatsData[sportsStats[sport]] = {
        weight_class: data.get(`${sport}_weightClass`),
        catch_weight_range: data.get(`${sport}_catchWeight`) === 'yes' ? {
          min: parseFloat(data.get(`${sport}_catchWeightMin`)) || 0.0,
          max: parseFloat(data.get(`${sport}_catchWeightMax`)) || 0.0
        } : null,
        years_experience: parseInt(data.get(`${sport}_yearsExperience`)) || 0,
        professional: data.get(`${sport}_professional`) === 'true',
        amateur_record: {
          wins: parseInt(data.get(`${sport}_amateurWins`)) || 0,
          losses: parseInt(data.get(`${sport}_amateurLosses`)) || 0,
          draws: parseInt(data.get(`${sport}_amateurDraws`)) || 0
        },
        pro_record: {
          wins: parseInt(data.get(`${sport}_proWins`)) || 0,
          losses: parseInt(data.get(`${sport}_proLosses`)) || 0,
          draws: parseInt(data.get(`${sport}_proDraws`)) || 0
        }
      };
    }
  });

  const fighterData = {
    first_name: data.get('firstName'),
    last_name: data.get('lastName'),
    nickname: data.get('nickname'),
    dob: new Date(data.get('dob')),
    height: parseInt(data.get('height')) || null,
    reach: parseInt(data.get('reach')) || null,
    gender: data.get('gender').toUpperCase(),
    active: data.get('active') === 'true',
    tapology_url: data.get('tapologyUrl') || null,
    ...fighterStatsData
  };

  const validationErrors = validateForm(fighterData, fighterStatsData);
  if (validationErrors.length > 0) {
    return { message: validationErrors, success: false }
  }

  const token = await checkGymOrAdminLoader();
  const authUrl = process.env.REACT_APP_AUTH_URL
  const response = await fetch(authUrl + '/api/v1/fighters/update/' + params.fighterId, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(fighterData)
  });

  const resData = await response.json();

  if (response.status === 400 || response.status === 401 || response.status === 403 || response.status === 404 || response.status === 409) {
    return resData;
  }

  if (!response.ok) {
    throw json({ message: resData.message }, { status: 500 });
  }
  return redirect('/fighter/' + params.fighterId);
}