import styles from './Footer.module.css';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.contact}>
                    <h3>Contact</h3>
                    <div className={styles.contactLinks}>
                        <p className={styles.email}>support@fightconnect.com</p>
                    </div>
                </div>
                <div className={styles.socials}>
                    <h3>Follow Us</h3>
                    <div className={styles.socialLinks}>
                        <a href="https://instagram.com/fightconnectofficial"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={process.env.PUBLIC_URL + '/instagram-icon.png'} alt="Logo" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;