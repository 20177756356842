import { useRouteLoaderData, Link } from 'react-router-dom';
import { decodeToken } from '../../util/auth';

import Modal from '../ui/Modal';

import styles from './DirectoryItemModal.module.css'

const DirectoryItemModal = ({ previewItem, onClose }) => {
  const token = useRouteLoaderData('root');

  let decodedToken = null;
  if (token) {
    decodedToken = decodeToken(token);
  }

  const renderExtraItems = () => {
    if (previewItem.extra_info && Array.isArray(previewItem.extra_info)) {
      return previewItem.extra_info.map((item, index) => (
        <p key={index}><strong>{item.key}:</strong> {item.value}</p>
      ));
    }
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <div className={styles.previewContainer}>
        <h2>{previewItem.name}</h2>
        <div className={styles.info}>
          <p><strong>Type:</strong> {previewItem.type}</p>
          <p><strong>Regions:</strong> {previewItem.regions.join(', ')}</p>
          {previewItem.email &&
            <p><strong>Email:</strong> {previewItem.email}</p>
          }
          {previewItem.phone_number &&
            <p><strong>Phone:</strong> {previewItem.phone_number}</p>
          }
          {previewItem.address &&
            <p><strong>Address:</strong> {previewItem.address}</p>
          }
          {previewItem.website && (
            <p>
              <strong>Website: </strong>
              <a
                href={previewItem.website.startsWith('http://') || previewItem.website.startsWith('https://') ? previewItem.website : `https://${previewItem.website}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to visit
              </a>
            </p>
          )}
          {renderExtraItems()}
        </div>
        {decodedToken && decodedToken.authorities[0] === 'ROLE_ADMIN' &&
          <Link className={styles.editBtn} to={`/directory/${previewItem.id}/edit`}>
            Edit Directory Item
          </Link>
        }
      </div>
    </Modal>
  );
}

export default DirectoryItemModal;