import { redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

export const getTokenDuration = () => {
  const storedExpirationDate = localStorage.getItem('expiration');
  const expirationDate = new Date(storedExpirationDate);
  const now = new Date();
  const duration = expirationDate.getTime() - now.getTime();
  return duration;
}

export const refreshToken = async (token) => {
  let newToken;
  console.log('Refreshing token');
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      "isRefreshToken": "true"
    }
  };
  const authUrl = process.env.REACT_APP_AUTH_URL
  const response = await fetch(authUrl + '/api/v1/auth/refreshtoken', options)
    .then(response => response.json())
    .then(data => {
      if (!data.token) {
        removeToken();
        console.log('An error occurred');
        return redirect('/login');
      } else {
        newToken = data.token;
        storeToken(newToken);
      }
      return newToken;
    })
    .catch(error => {
      console.error('An error occurred: ', error);
      removeToken();
      return redirect('/login');
    });
  return response;
}

export const pendingLoader = async () => {
  if (process.env.REACT_APP_SITE_PENDING === 'true') {
    return redirect('/');
  }
}

export const getAuthToken = async () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return null;
  }

  if (getTokenDuration() < 0) {
    removeToken();
    const newToken = await refreshToken(token);
    return newToken;
  }

  return token;
}

export const tokenLoader = async () => {
  const token = await getAuthToken();
  return token;
}

export const checkNoAuthLoader = async () => {
  const token = await getAuthToken();
  if (token) {
    return redirect('/');
  }
  return "OK";
}

export const checkAuthLoader = async () => {
  const token = await getAuthToken();

  if (!token) {
    return redirect('/');
  }
  return token;
}

export const checkGymLoader = async () => {
  const token = await getAuthToken();
  if (!token || decodeToken(token).authorities[0] !== 'ROLE_GYM') {
    return redirect('/');
  }
  return token;
} 

export const checkPromotionLoader = async () => {
  const token = await getAuthToken();
  if (!token || decodeToken(token).authorities[0] !== 'ROLE_PROMOTION') {
    return redirect('/');
  }
  return token;
} 

export const checkAdminLoader = async () => {
  const token = await getAuthToken();

  if (!token || decodeToken(token).authorities[0] !== 'ROLE_ADMIN') {
    return redirect('/');
  }
  return token;
}

export const checkPromotionOrAdminLoader = async () => {
  const token = await getAuthToken();
  if (!token || (decodeToken(token).authorities[0] !== 'ROLE_PROMOTION' && decodeToken(token).authorities[0] !== 'ROLE_ADMIN')) {
    return redirect('/');
  }
  return token;
} 

export const checkGymOrAdminLoader = async () => {
  const token = await getAuthToken();
  if (!token || (decodeToken(token).authorities[0] !== 'ROLE_GYM' && decodeToken(token).authorities[0] !== 'ROLE_ADMIN')) {
    return redirect('/');
  }
  return token;
} 

export const storeToken = (token) => {
  localStorage.setItem('token', token);
  const expiration = new Date();
  expiration.setMinutes(expiration.getMinutes() + 15);
  // expiration.setSeconds(expiration.getSeconds() + 30);
  localStorage.setItem('expiration', expiration.toISOString());
}

export const decodeToken = (token) => {
  return jwt_decode(token);
}

export const removeToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expiration');
}
