import { useState, useRef, useEffect } from 'react';
import {
  Form,
  useLocation,
  useLoaderData,
  useActionData,
  useNavigation,
} from 'react-router-dom';

import { Autocomplete } from "@react-google-maps/api";
import { useGoogleMapsContext } from '../ui/GoogleMapsContext';

import styles from './DirectoryItemForm.module.css';
import typesList from './types.js';
import regionsList from '../../util/regions';

const DirectoryItemForm = () => {
  const { isMapsApiLoaded } = useGoogleMapsContext();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === 'submitting';
  const data = useLoaderData();
  const actionData = useActionData()
  const location = useLocation();
  const isAddDirectoryItem = location.pathname === '/directory/add';

  const [type, setType] = useState(data?.type || "");
  const [name, setName] = useState(data?.name || "");
  const [regions, setRegions] = useState(data?.regions || []);
  const [email, setEmail] = useState(data?.email || "");
  const [website, setWebsite] = useState(data?.website || "");
  const [phoneNumber, setPhoneNumber] = useState(data?.phone_number || "");

  const initialCustomFieldA = data?.extra_info && data.extra_info[0] ? data.extra_info[0] : { key: '', value: '' };
  const initialCustomFieldB = data?.extra_info && data.extra_info[1] ? data.extra_info[1] : { key: '', value: '' };

  const [customFieldA, setCustomFieldA] = useState(initialCustomFieldA);
  const [customFieldB, setCustomFieldB] = useState(initialCustomFieldB);

  const [showCustomFields, setShowCustomFields] = useState(data?.extra_info ? data.extra_info.length : 0);

  const autocompleteRef = useRef(null);

  const [selectedAddress, setSelectedAddress] = useState(data?.address || '');

  const onLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onPlaceChanged = () => {
    if (autocompleteRef.current !== null) {
      const place = autocompleteRef.current.getPlace();

      const hasStreetNumberOrPremise = place.address_components.some(
        (component) =>
          component.types.includes("street_number") ||
          component.types.includes("premise")
      );

      if (!hasStreetNumberOrPremise) {
        // Show an error message
        alert("Please enter a specific address, not just a street or city.");
      } else {
        setSelectedAddress(place.formatted_address);
      }
    }
  };

  const handleResetAddress = () => {
    setSelectedAddress('');
  }

  const handleRegionChange = (event) => {
    const { value } = event.target;

    setRegions((prevRegions) => {
      if (prevRegions.includes(value)) {
        return prevRegions.filter(region => region !== value);
      } else {
        return [...prevRegions, value];
      }
    });
  };


  const handleAddCustomField = () => {
    setShowCustomFields(prevState => prevState + 1);
  };

  if (!isMapsApiLoaded) return 'Loading Google Maps API...';

  return (
    <>
      <div className={styles.background} />
      <div className={styles.container}>
        <Form method="post" className={styles.form}>
          <img className={styles.authlogo} src={process.env.PUBLIC_URL + '/authlogo.png'} alt="Logo" />
          <h1>{isAddDirectoryItem ? 'Add Directory Item' : 'Edit Directory Item'}</h1>
          {actionData && actionData.errors && (
            <div className={styles.errors}>
              <ul>
                {Object.values(actionData.errors).map((err) => (
                  <li key={err}>{err}</li>
                ))}
              </ul>
            </div>
          )}
          {actionData && actionData.message &&
            <div className={actionData.success ? styles.success : styles.errors}>
              <p>{actionData.message}</p>
            </div>
          }

          <p>
            <label htmlFor="type">Type</label>
            <select id="type" name="type" value={type} onChange={(event) => setType(event.target.value)} required>
              {typesList.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </p>

          <p>
            <label htmlFor="name">Name</label>
            <input id="name" type="text" name="name" value={name} onChange={(event) => setName(event.target.value)} required />
          </p>


          <label htmlFor="regions">Regions Covered (Optional)</label>
          <div className={styles.regions}>
            {regionsList.map((regionName) => (
              <div key={regionName}>
                <input
                  type="checkbox"
                  id={regionName}
                  name="regions[]"
                  value={regionName}
                  checked={regions.includes(regionName)}
                  onChange={handleRegionChange}
                />
                <label htmlFor={regionName}>{regionName}</label>
              </div>
            ))}
          </div>

          <p>
            <label htmlFor="email">Email (Optional)</label>
            <input id="email" type="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} />
          </p>

          <p>
            <label htmlFor="phoneNumber">Phone Number (Optional)</label>
            <input id="phoneNumber" type="text" name="phoneNumber" value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)} />
          </p>

          {selectedAddress === '' &&
            <>
              <label htmlFor="address">Address (Optional)</label>
              <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
                options={{
                  componentRestrictions: { country: "uk" },
                  types: ["address"],
                }}
              >
                <input
                  id="address"
                  type="text"
                  name="address"
                  placeholder="Start typing the address..."
                  autoComplete="fight-connect"
                />
              </Autocomplete>
            </>
          }
          {selectedAddress !== '' &&
            <>
              <p>
                <label htmlFor="address">Address</label>
                <input id="address" type="text" name="address" value={selectedAddress} readOnly />
              </p>
              <p className={styles.resetaddress} onClick={handleResetAddress}>Click here to search for another address</p>
            </>
          }

          <p>
            <label htmlFor="website">Website (Optional)</label>
            <input id="website" type="text" name="website" value={website} onChange={(event) => setWebsite(event.target.value)} />
          </p>

          {showCustomFields >= 1 && (
            <p>
              <label htmlFor="customFieldAKey">Custom Field A Key</label>
              <input
                id="customFieldAKey"
                type="text"
                name="customFieldAKey"
                value={customFieldA.key}
                onChange={(e) => setCustomFieldA({ ...customFieldA, key: e.target.value })}
              />
              <label htmlFor="customFieldAValue">Custom Field A Value</label>
              <input
                id="customFieldAValue"
                type="text"
                name="customFieldAValue"
                value={customFieldA.value}
                onChange={(e) => setCustomFieldA({ ...customFieldA, value: e.target.value })}
              />
            </p>
          )}
          {showCustomFields >= 2 && (
            <p>
              <label htmlFor="customFieldBKey">Custom Field B Key</label>
              <input
                id="customFieldBKey"
                type="text"
                name="customFieldBKey"
                value={customFieldB.key}
                onChange={(e) => setCustomFieldB({ ...customFieldB, key: e.target.value })}
              />
              <label htmlFor="customFieldBValue">Custom Field B Value</label>
              <input
                id="customFieldBValue"
                type="text"
                name="customFieldBValue"
                value={customFieldB.value}
                onChange={(e) => setCustomFieldB({ ...customFieldB, value: e.target.value })}
              />
            </p>
          )}
          {showCustomFields < 2 && (
            <div className={styles.customFieldBtn}>
              <button type="button" onClick={handleAddCustomField}>Add Custom Field</button>
            </div>
          )}

          <div className={styles.actions}>
            <button disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : isAddDirectoryItem ? 'Add Directory Item' : 'Update Directory Item'}
            </button>
          </div>
        </Form>
      </div>
    </>
  )
}

export default DirectoryItemForm;