import { Outlet } from 'react-router-dom';
import FighterContent from '../components/explore/FighterContent';

const Fighter = () => {
  return <>
    <Outlet />
    <FighterContent />
  </>
}

export default Fighter;

export const loader = async ({ params }) => {
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const fightersResponse = await fetch(authUrl + '/api/v1/fighters/fetch/' + params.fighterId);
  const fightersResData = await fightersResponse.json();

  const eventsResponse = await fetch(authUrl + '/api/v1/events/fights/' + params.fighterId);
  const eventsResData = await eventsResponse.json();

  // Get fighter IDs from fights array
  const fightFighterIds = eventsResData.flatMap(fight => [
    fight.fighter_a.fighter_id,
    fight.fighter_b.fighter_id,
  ]);

  // Get fighters data for the fights
  const fightsFightersResponse = await fetch(authUrl + '/api/v1/fighters/list', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fightFighterIds)
  });
  const fightsFightersResData = await fightsFightersResponse.json();
  const fightsFightersMap = new Map(fightsFightersResData.map(fighter => [fighter.id, fighter]));

  // Fetch gyms
  const gymsResponse = await fetch(authUrl + '/api/v1/accounts/gyms');
  const gymsResData = await gymsResponse.json();

  // Create a map for gyms
  const gymsMap = new Map(gymsResData.map(gym => [gym.user_id, gym.organisation]));

  // Replace fighter_a and fighter_b objects with the received data
  eventsResData.forEach(fight => {
    fight.fighter_a = { ...fightsFightersMap.get(fight.fighter_a.fighter_id), gym_name: gymsMap.get(fight.fighter_a.gym_id) };
    fight.fighter_b = { ...fightsFightersMap.get(fight.fighter_b.fighter_id), gym_name: gymsMap.get(fight.fighter_b.gym_id) };
  });
  
  return { ...fightersResData, fights: eventsResData }
}