import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Form, Link } from 'react-router-dom';
import { useRouteLoaderData } from 'react-router-dom';
import { decodeToken, tokenLoader } from '../../util/auth';
import moment from 'moment';
import { useInView } from 'react-intersection-observer';
import { useSocket } from '../../context/SocketContext';

import styles from './MessageDisplay.module.css';

const MessageDisplay = ({ inboxMessages, setInboxMessages, currentUser, updateInboxData, fetchInboxMessages, refreshInboxData, messageDisplayState, setMessageDisplayState, isEditing, setIsEditing, newInboxName, setNewInboxName, kickingMember, setKickingMember, adminToggle, setAdminToggle, selectedNewUsers, setSelectedNewUsers, newUserSearchTerm, setNewUserSearchTerm }) => {
  const token = useRouteLoaderData('root');
  const userId = decodeToken(token).activeRoleUserId;
  const [availableUsers, setAvailableUsers] = useState([]);
  const [deletingMessage, setDeletingMessage] = useState(null);
  const [charCount, setCharCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [ref, inView] = useInView({
    threshold: 0,
  });

  const { reconnectWebSocket } = useSocket();

  const inputRef = useRef(null);
  const isAdmin = inboxMessages?.participants?.find(p => p.participant_id === userId)?.admin || false;

  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    if (inboxMessages?.participants?.find(p => p.participant_id === userId)) {
      setIsMuted(!inboxMessages.participants.find(p => p.participant_id === userId).notifications);
    }
  }, [inboxMessages?.participants, userId]);

  const handleToggleMute = async () => {
    try {
      const latestToken = await tokenLoader();
      const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/api/v1/inbox/toggle-notifications/${inboxMessages.id}/${isMuted}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${latestToken}`,
        },
      });

      if (response.ok) {
        setIsMuted(!isMuted);
        refreshInboxData(false);
      } else {
        console.error('Failed to toggle notifications');
      }
    } catch (error) {
      console.error('Error toggling notifications:', error);
    }
  };

  useEffect(() => {
    const scrollToBottom = () => {
      const messageList = document.querySelector(`.${styles.messageList}`);
      if (messageList) {
        messageList.scrollTop = messageList.scrollHeight;
      }
    };

    if (!messageDisplayState.showAddParticipants && !messageDisplayState.showParticipants) {
      scrollToBottom();
    }
  }, [inboxMessages, messageDisplayState]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inboxMessages]);


  useEffect(() => {
    const loadMoreMessages = async () => {
      if (!inboxMessages || inboxMessages.messages.length === 0 || !hasMore) return;

      const oldestMessageId = inboxMessages.messages[0].id;
      const olderMessages = await fetchInboxMessages(inboxMessages.id, 25, oldestMessageId);

      if (olderMessages && olderMessages.messages.length > 0) {
        inboxMessages.messages = [...olderMessages.messages, ...inboxMessages.messages];
        setTimeout(() => {
          const messageList = document.querySelector(`.${styles.messageList}`);
          if (messageList) {
            messageList.scrollTop += 10;
          }
        }, 0);
      } else {
        setHasMore(false);
      }
    };
    if (inView) {
      loadMoreMessages();
    }
  }, [inView, fetchInboxMessages, inboxMessages, setInboxMessages, hasMore]);

  const handleSaveClick = useCallback(async () => {
    if (!inboxMessages) return;

    try {
      const latestToken = await tokenLoader();
      const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/api/v1/inbox/rename`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${latestToken}`,
        },
        body: JSON.stringify({
          inbox_id: inboxMessages.id,
          inbox_name: newInboxName.trim(),
        }),
      });

      if (response.ok) {
        setIsEditing(false);
        // Update the inbox name locally
        setInboxMessages(prevMessages => ({
          ...prevMessages,
          inbox_name: newInboxName.trim()
        }));
        // Update the inbox data in the parent component
        updateInboxData({
          ...inboxMessages,
          inbox_name: newInboxName.trim()
        });
        // Refresh the inbox list without resetting the current inbox
        refreshInboxData(false);
      } else {
        console.error('Failed to rename inbox');
      }
    } catch (error) {
      console.error('Error renaming inbox:', error);
    }
  }, [inboxMessages, newInboxName, setInboxMessages, updateInboxData, refreshInboxData, setIsEditing]);

  const handleEditClick = () => {
    if (inboxMessages) {
      setIsEditing(true);
      setNewInboxName(inboxMessages.inbox_name || '');
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const toggleParticipants = () => {
    if (messageDisplayState.showAddParticipants) {
      setMessageDisplayState({
        showAddParticipants: false,
        showParticipants: true
      });
      setSelectedNewUsers([]);
      setNewUserSearchTerm('');
    } else {
      setMessageDisplayState(prev => ({
        ...prev,
        showParticipants: !prev.showParticipants
      }));
    }
  };

  const handleKickMember = (participantId) => {
    setKickingMember(participantId);
  };

  const confirmKickMember = async (participantId) => {
    try {
      const latestToken = await tokenLoader();
      const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/api/v1/inbox/remove-participant/${inboxMessages.id}/${participantId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${latestToken}`,
        },
      });

      if (response.ok) {
        setKickingMember(null);
        await fetchInboxMessages(inboxMessages.id);
        refreshInboxData();
        // Keep the participant screen open
        setMessageDisplayState(prev => ({
          ...prev,
          showParticipants: true,
          showAddParticipants: false
        }));
      } else {
        console.error('Failed to kick member');
      }
    } catch (error) {
      console.error('Error kicking member:', error);
    }
  };

  const handleAdminToggle = (participantId) => {
    setAdminToggle(participantId);
  };

  const confirmAdminToggle = async (participantId, isCurrentlyAdmin) => {
    try {
      const latestToken = await tokenLoader();
      const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/api/v1/inbox/toggle-admin/${inboxMessages.id}/${participantId}/${!isCurrentlyAdmin}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${latestToken}`,
        },
      });

      if (response.ok) {
        setAdminToggle(null);
        await fetchInboxMessages(inboxMessages.id);
        refreshInboxData();
        // Keep the participant screen open
        setMessageDisplayState(prev => ({
          ...prev,
          showParticipants: true,
          showAddParticipants: false
        }));
      } else {
        console.error('Failed to toggle admin status');
      }
    } catch (error) {
      console.error('Error toggling admin status:', error);
    }
  };

  const handleToggleAddParticipants = () => {
    setMessageDisplayState(prev => ({
      ...prev,
      showAddParticipants: true
    }));
  };

  const fetchAvailableUsers = useCallback(async () => {
    const token = await tokenLoader();
    const authUrl = process.env.REACT_APP_AUTH_URL;
    try {
      const response = await fetch(`${authUrl}/api/v1/accounts/explore`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      if (!response.ok) throw new Error('Failed to fetch users');
      const allUsers = await response.json();
      return allUsers.filter(user => !inboxMessages.participants.some(p => p.participant_id === user.user_id));
    } catch (error) {
      console.error('Error fetching available users:', error);
      return [];
    }
  }, [inboxMessages?.participants]);

  const handleNewUserCheckboxChange = (userId) => {
    setSelectedNewUsers(prev =>
      prev.includes(userId) ? prev.filter(id => id !== userId) : [...prev, userId]
    );
  };

  const handleAddMembers = async () => {
    const token = await tokenLoader();
    const authUrl = process.env.REACT_APP_AUTH_URL;
    try {
      const response = await fetch(`${authUrl}/api/v1/inbox/add-participant/${inboxMessages.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ participants: selectedNewUsers })
      });
      if (!response.ok) throw new Error('Failed to add participants');

      await fetchInboxMessages(inboxMessages.id);
      refreshInboxData();

      // Keep the participant screen open after adding members
      setMessageDisplayState(prevState => ({
        ...prevState,
        showAddParticipants: false,
        showParticipants: true
      }));
      setSelectedNewUsers([]);
      setNewUserSearchTerm('');
    } catch (error) {
      console.error('Error adding participants:', error);
    }
  };

  useEffect(() => {
    if (messageDisplayState.showAddParticipants) {
      fetchAvailableUsers().then(users => setAvailableUsers(users));
    }
  }, [messageDisplayState.showAddParticipants, fetchAvailableUsers]);

  if (!inboxMessages) {
    return null;
  }

  const getParticipantInfo = (senderId) => {
    return inboxMessages.participants.find(p => p.participant_id === senderId) || {};
  };

  const getDisplayName = () => {
    if (inboxMessages.group) {
      return inboxMessages.inbox_name || 'Group Chat';
    }
    return inboxMessages.participants.find(p => p.participant_id !== userId)?.participant_name || "Unknown";
  }

  const renderDisplayName = () => {
    const displayName = getDisplayName();

    if (inboxMessages.special && inboxMessages.special_type === 'EVENT') {
      return (
        <Link to={`/event/${inboxMessages.special_id}`} className={styles.displayName}>
          {displayName}
        </Link>
      );
    } else if (inboxMessages.group) {
      return <span className={styles.nonLinkDisplayName}>{displayName}</span>;
    } else {
      const otherParticipant = inboxMessages.participants.find(p => p.participant_id !== userId);
      if (otherParticipant) {
        return (
          <Link to={`/${otherParticipant.participant_type === 'ROLE_PROMOTION' ? 'promotion' : 'gym'}/${otherParticipant.participant_id}`} className={styles.displayName}>
            {displayName}
          </Link>
        );
      }
    }
    return <span className={styles.nonLinkDisplayName}>{displayName}</span>;
  }

  const handleInputChange = (e) => {
    const textarea = e.target;
    setCharCount(textarea.value.length);
    textarea.style.height = 'auto';
    textarea.style.height = `${Math.min(textarea.scrollHeight, 4 * 24)}px`; // 4 lines max
    textarea.scrollTop = textarea.scrollHeight;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      e.currentTarget.form.requestSubmit();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);
    const message = formData.get('message').trim();


    if (message.length > 250) {
      alert('Message cannot exceed 250 characters.');
      return;
    }

    if (message !== '') {
      await reconnectWebSocket();
      try {
        const latestToken = await tokenLoader();
        const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/api/v1/inbox/send-message`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${latestToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            inbox_id: inboxMessages.id,
            message: message,
          }),
        });

        if (!response.ok) {
          console.error('Failed to send message');
          return;
        }
      } catch (error) {
        console.error('Error sending message:', error);
        return;
      }

      form.reset();
      setCharCount(0); // Reset character count

      // Reset the height of the textarea
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
      }

      // Scroll to the bottom of the message list
      setTimeout(() => {
        const messageList = document.querySelector(`.${styles.messageList}`);
        if (messageList) {
          messageList.scrollTop = messageList.scrollHeight;
        }
      }, 100);
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleDeleteMessage = (messageId) => {
    setDeletingMessage(messageId);
  };

  const confirmDeleteMessage = async (messageId) => {
    try {
      const latestToken = await tokenLoader();
      const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/api/v1/inbox/delete-message/${messageId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${latestToken}`,
        },
      });

      if (response.ok) {
        setDeletingMessage(null);
      } else {
        console.error('Failed to delete message');
      }
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.inboxHeader}>
        {isEditing ? (
          <div className={styles.editContainer}>
            <input
              type="text"
              value={newInboxName}
              onChange={(e) => setNewInboxName(e.target.value)}
              className={styles.editInput}
            />
            <button onClick={handleSaveClick} className={styles.editAction}>&#x2713;</button>
            <button onClick={handleCancelClick} className={styles.editAction}>&#x2717;</button>
          </div>
        ) : (
          <>
            <div className={styles.inboxHeaderLeft}>
              {renderDisplayName()}
              {inboxMessages.group && !inboxMessages.special && isAdmin && (
                <button onClick={handleEditClick} className={styles.editButton}>✎</button>
              )}
            </div>
            {inboxMessages.group && !inboxMessages.special && isAdmin && (
              <div className={styles.participantsButtonContainer}>
                {messageDisplayState.showParticipants || messageDisplayState.showAddParticipants ? (
                  <button onClick={toggleParticipants} className={styles.goBackButton}>
                    Go Back
                  </button>
                ) : (
                  <>
                    <button onClick={handleToggleMute} className={styles.muteButton}>
                      {isMuted ? 'Unmute' : 'Mute'}
                    </button>
                    <Link to={`/inbox/${inboxMessages.id}/upload-image`} className={styles.changeImageButton}>
                      Change Image
                    </Link>
                    <button onClick={toggleParticipants} className={styles.participantsToggle}>
                      👥
                    </button>
                  </>
                )}
                {messageDisplayState.showParticipants && !messageDisplayState.showAddParticipants && (
                  <>
                    <button onClick={handleToggleAddParticipants} className={styles.addMembersButton}>
                      Add Members
                    </button>
                    <Link to={`/inbox/${inboxMessages.id}/delete`} className={styles.deleteInboxButton}>
                      Delete Inbox
                    </Link>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </h2>
      {messageDisplayState.showAddParticipants ? (
        <div className={styles.addParticipantsContainer}>
          <div className={styles.searchAndAddBar}>
            <input
              type="text"
              placeholder="Search users..."
              value={newUserSearchTerm}
              onChange={(e) => setNewUserSearchTerm(e.target.value)}
              className={styles.search}
            />
            <button
              onClick={handleAddMembers}
              disabled={selectedNewUsers.length === 0}
              className={styles.addMembersButton}
            >
              Add Members
            </button>
          </div>
          <ul className={styles.userList}>
            {availableUsers
              .filter(user =>
                user.organisation.toLowerCase().includes(newUserSearchTerm.toLowerCase()) ||
                selectedNewUsers.includes(user.user_id)
              )
              .map(user => (
                <li key={user.user_id} className={styles.userItem}>
                  <input
                    type="checkbox"
                    className={styles.checkbox}
                    id={`new-user-${user.user_id}`}
                    checked={selectedNewUsers.includes(user.user_id)}
                    onChange={() => handleNewUserCheckboxChange(user.user_id)}
                  />
                  <label htmlFor={`new-user-${user.user_id}`}>{user.organisation}</label>
                </li>
              ))
            }
          </ul>
        </div>
      ) : messageDisplayState.showParticipants ? (
        <ul className={styles.participantsList}>
          {inboxMessages.participants.filter(p => p.participant_id !== userId).map((participant) => (
            <li key={participant.participant_id} className={styles.participantItem}>
              {inboxMessages.group && !inboxMessages.special && isAdmin && (
                <>
                  {adminToggle === participant.participant_id ? (
                    <div className={styles.adminToggleConfirmation}>
                      <span>{participant.admin ? 'Remove Admin?' : 'Make Admin?'}</span>
                      <button onClick={() => confirmAdminToggle(participant.participant_id, participant.admin)} className={styles.adminAction}>&#x2713;</button>
                      <button onClick={() => setAdminToggle(null)} className={styles.adminAction}>&#x2717;</button>
                    </div>
                  ) : (
                    kickingMember !== participant.participant_id && <button onClick={() => handleAdminToggle(participant.participant_id)} className={styles.adminToggle}></button>
                  )}
                </>
              )}
              <span className={styles.participantName}>
                <span className={styles.participantNameText}>
                  {participant.participant_name}
                  {participant.admin && <span className={styles.adminBadge}> (Admin)</span>}
                </span>
              </span>
              {kickingMember === participant.participant_id ? (
                <div className={styles.kickConfirmation}>
                  <span>Remove Member?</span>
                  <button onClick={() => confirmKickMember(participant.participant_id)} className={styles.kickAction}>&#x2713;</button>
                  <button onClick={() => setKickingMember(null)} className={styles.kickAction}>&#x2717;</button>
                </div>
              ) : (
                adminToggle !== participant.participant_id && <button onClick={() => handleKickMember(participant.participant_id)} className={styles.kickAction}>&#x2717;</button>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <>
          <ul className={styles.messageList}>
            <li ref={ref} style={{ height: 1, opacity: 0 }}></li>
            {inboxMessages.messages.length === 0 && <li>No messages yet...</li>}
            {inboxMessages.messages.map((message) => {
              const sender = getParticipantInfo(message.sender_id);
              return (
                <li
                  key={message.id}
                  className={`${styles.message} ${message.sender_id === currentUser ? styles.userMessage : styles.otherUserMessage}`}
                >
                  {message.sender_id !== currentUser && (
                    <img
                      src={`${message.sender_image || `${process.env.PUBLIC_URL}/defaultpicture.png`}?timestamp=${new Date().getTime()}`}
                      alt={message.sender_name}
                      className={styles.avatar}
                    />
                  )}
                  <div className={styles.messageContent}>
                    <div className={styles.messageHeader}>
                      <div>
                        {sender.participant_id ? (
                          <Link to={`/${sender.participant_type === 'ROLE_PROMOTION' ? 'promotion' : 'gym'}/${sender.participant_id}`} className={styles.displayName}>
                            <strong>{message.sender_name}</strong>
                          </Link>
                        ) : (
                          <strong>{message.sender_name}</strong>
                        )}
                        <small>
                          {!sender.participant_id && ' (left inbox)'}</small></div>
                      <div className={styles.messageActions}>
                        {deletingMessage === message.id ? (
                          <div className={styles.deleteConfirmation}>
                            <span>Unsend Message?</span>
                            <button onClick={() => confirmDeleteMessage(message.id)} className={styles.deleteAction}>&#x2713;</button>
                            <button onClick={() => setDeletingMessage(null)} className={styles.deleteAction}>&#x2717;</button>
                          </div>
                        ) : (
                          <>
                            <small className={styles.messageTimestamp}>
                              {moment.utc(message.timestamp).tz('Europe/London').format('DD/MM/yyyy HH:mm')}
                            </small>
                            {message.sender_id === currentUser && (
                              <button onClick={() => handleDeleteMessage(message.id)} className={styles.deleteButton}>&#x2717;</button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div>{message.message}</div>
                  </div>
                  {message.sender_id === currentUser && (
                    <img
                      src={`${message.sender_image || `${process.env.PUBLIC_URL}/defaultpicture.png`}?timestamp=${new Date().getTime()}`}
                      alt={message.sender_name}
                      className={styles.avatar}
                    />
                  )}
                </li>
              );
            })}
          </ul>
          <Form method="post" className={styles.inputForm} onSubmit={handleSubmit}>
            <input type="hidden" name="inboxId" value={inboxMessages.id} />
            <div className={styles.charCount} style={{ color: charCount > 250 ? 'red' : 'white' }}>
              {charCount}/250
            </div>
            <textarea
              ref={inputRef}
              name="message"
              placeholder="Type something..."
              className={styles.messageInput}
              autoComplete="off"
              rows="1"
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              style={{ overflowY: 'auto' }}
            />
            <button type="submit" className={styles.sendButton}>
              <span className={styles.sendIcon}>➤</span>
            </button>
          </Form>
        </>
      )}
    </div>
  );
};

export default MessageDisplay;