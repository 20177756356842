import { useState, useEffect } from 'react';

import {
  Form,
  Link,
  useLocation,
  useSubmit
} from 'react-router-dom';

import { storeToken } from '../../util/auth';
import { useSocket } from '../../context/SocketContext';
import { geocodeAddress } from '../../util/maps'

import styles from './AuthForm.module.css';

import counties from '../../util/counties.json';

const AuthForm = () => {
  const submit = useSubmit();
  const { connectWebSocket, clearNotifications, fetchUnreadCount, fetchUnreadNotificationsCount, fetchPendingMatchCount } = useSocket();
  const [errorData, setErrorData] = useState(null);
  const location = useLocation();
  const isLogin = location.pathname.includes('/login');
  const isResetPassword = location.pathname.includes('/reset-password');
  const isRequestPasswordReset = location.pathname.includes('/request-password-reset');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [postcode, setPostcode] = useState('');
  const [suggestions, setSuggestions] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [province, setProvince] = useState('');
  const [showProvince, setShowProvince] = useState(false);
  const [orgType, setOrgType] = useState('NULL');
  const [areaCode, setAreaCode] = useState('+44');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleTouchMove = (event) => {
      if (event.target.closest('.addressList')) {
        event.stopPropagation();
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  if (errorData) {
    window.scrollTo(0, 0);
  }

  const validateForm = (regData) => {
    let validationErrors = [];
    if (regData.orgType === 'NULL') {
      validationErrors.push('Please select an organisation type from the dropdown.');
    }
    if (regData.orgType === 'gym' && (!regData.address || regData.address === null || regData.address === '')) {
      validationErrors.push('Please enter an address.');
    }
    if (regData.orgType === 'gym' && (!regData.region || regData.region === '')) {
      validationErrors.push('Address invalid (does not contain a county/province).');
    }
    if (regData.password.length < 8) {
      validationErrors.push('Password must be at least 8 characters.');
    }
    if (!/[A-Z]/.test(regData.password)) {
      validationErrors.push('Password must contain at least 1 upper case letter.');
    }
    if (!/[a-z]/.test(regData.password)) {
      validationErrors.push('Password must contain at least 1 lower case letter.');
    }
    if (regData.password !== regData.confirmPassword) {
      validationErrors.push('Password and confirm password do not match.')
    }
    if (!/^\d{10}$/.test(regData.phoneNumber)) {
      validationErrors.push("This is not a valid phone number.");
    }
    return validationErrors;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isLogin) {
      setIsSubmitting(true);
      const formData = new FormData(event.target);
      const authData = {
        username: formData.get('email'),
        password: formData.get('password'),
        fcm_token: localStorage.getItem('fcm_token')
      };

      try {
        const authUrl = process.env.REACT_APP_AUTH_URL;
        const response = await fetch(`${authUrl}/api/v1/auth/authenticate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(authData)
        });

        const resData = await response.json();

        if (response.ok) {
          const token = resData.token;
          storeToken(token);
          connectWebSocket();
          clearNotifications();
          fetchUnreadCount();
          fetchUnreadNotificationsCount();
          fetchPendingMatchCount();
          submit(event.target, { method: 'post' });
        } else {
          setErrorData(resData);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error('Login error:', error);
        setErrorData({ message: 'An unexpected error occurred. Please try again.' });
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(true);
      const data = new FormData(event.target);
      const regData = {
        email: data.get('email'),
        password: data.get('password'),
        confirmPassword: data.get('confirmPassword'),
        orgName: data.get('orgName'),
        orgType: data.get('orgType'),
        address: data.get('address'),
        region: data.get('province'),
        contactEmail: data.get('contactEmail'),
        areaCode: data.get('areaCode'),
        phoneNumber: data.get('phoneNumber').replace(/^(?:\+440|440|44|\+44|0)/, '').replace(/\s+/g, '')
      }
      const validationErrors = validateForm(regData);
      if (validationErrors.length > 0) {
        setErrorData({ errors: validationErrors });
        setIsSubmitting(false);
      } else {
        const authData = {
          username: regData.email,
          password: regData.password,
          role: regData.orgType === 'gym' ? 'ROLE_GYM' : 'ROLE_PROMOTION',
          organisation: regData.orgName,
          contact_email: regData.contactEmail,
          area_code: regData.areaCode,
          phone_number: regData.phoneNumber,
          fcm_token: localStorage.getItem('fcm_token')
        }

        if (regData.address) {
          const geoLocation = await geocodeAddress(regData.address);
          authData.address = regData.address;
          authData.region = regData.region;
          authData.coordinates = {
            latitude: geoLocation.Latitude,
            longitude: geoLocation.Longitude
          }
        }

        try {
          const authUrl = process.env.REACT_APP_AUTH_URL
          const response = await fetch(authUrl + '/api/v1/auth/register', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(authData)
          });

          const resData = await response.json();

          if (response.ok) {
            submit(regData.email, { method: 'post' });
          } else {
            setErrorData(resData);
            setIsSubmitting(false);
          }
        } catch (error) {
          console.error('Registration error:', error);
          setErrorData({ message: 'An unexpected error occurred. Please try again.' });
          setIsSubmitting(false);
        }
      }
    }
  };

  const handlePostcodeChange = async (e) => {
    const value = e.target.value;
    setPostcode(value);
    if (value.length > 3) {
      try {
        const response = await fetch(`https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?Key=${process.env.REACT_APP_LOQATE_API_KEY}&Text=${e.target.value}&IsMiddleware=false&Countries=GB&Language=en-gb&Container=`);
        const result = await response.json();
        setSuggestions(result.Items);
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  }

  const handleSuggestionsClick = async (suggestion) => {
    if (suggestion.Type === 'Address') {
      try {
        const response = await fetch(`https://api.addressy.com/Capture/Interactive/Retrieve/v1.2/json3.ws?Key=${process.env.REACT_APP_LOQATE_API_KEY}&Id=${suggestion.Id}`);
        const result = await response.json();
        setSelectedAddress(result.Items[0].Label);
        if (!result.Items[0].Province) {
          setShowProvince(true);
        } else {
          setShowProvince(false);
          setProvince(result.Items[0].Province || '');
        }
      } catch (error) {
        console.error('Error fetching address:', error);
      }
    } else {
      try {
        const response = await fetch(`https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?Key=${process.env.REACT_APP_LOQATE_API_KEY}&Text=${suggestion.Text}&IsMiddleware=false&Countries=GB&Language=en-gb&Container=${suggestion.Id}`);
        const result = await response.json();
        setSuggestions(result.Items);
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    }
  }

  const handleResetAddress = () => {
    setPostcode('');
    setSuggestions([]);
    setSelectedAddress('');
    setProvince('');
    setShowProvince(false);
  }

  return (
    <>
      <div className={styles.background} />
      <div className={styles.container}>
        <Form method="post" className={styles.form} onSubmit={handleSubmit}>
          <img className={styles.authlogo} src={process.env.PUBLIC_URL + '/authlogo.png'} alt="Logo" />
          <h1>{isLogin ? 'Log in' : isResetPassword ? 'Reset Password' : 'Create an account'}</h1>
          {!isLogin && !isResetPassword && <h4 className={styles.subheading}>Only UK registrations are accepted during beta</h4>}
          {!isRequestPasswordReset && errorData && errorData.errors && (
            <div className={styles.errors}>
              <ul>
                {Object.values(errorData.errors).map((err) => (
                  <li key={err}>{err}</li>
                ))}
              </ul>
            </div>
          )}
          {!isRequestPasswordReset && errorData && errorData.message &&
            <div className={styles.errors}>
              <p>{errorData.message}</p>
            </div>
          }
          {isResetPassword && <>
            <p>
              <label htmlFor="password">Password</label>
              <input id="password" type="password" name="password" placeholder="Enter your password..." required />
            </p>
            <p>
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input id="confirmPassword" type="password" name="confirmPassword" placeholder="Enter your password again..." required />
            </p>
          </>}
          {!isResetPassword && <>
            <p>
              <label htmlFor="email">Email</label>
              <input id="email" type="email" name="email" placeholder="Enter your email..." required />
            </p>
            <p>
              <label htmlFor="password">Password</label>
              <input id="password" type="password" name="password" placeholder="Enter your password..." required />
            </p>
            {!isLogin &&
              <>
                <p>
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input id="confirmPassword" type="password" name="confirmPassword" placeholder="Enter your password again..." required />
                </p>
                <hr />
                <p>
                  <label htmlFor="orgName">Organisation Name</label>
                  <input id="orgName" type="text" name="orgName" placeholder="Enter the name of your organisation..." required />
                </p>
                <p>
                  <label htmlFor="orgType">Organisation Type</label>
                  <select id="orgType" name="orgType" onChange={(event) => setOrgType(event.target.value)}>
                    <option value="NULL">Please select an organisation type...</option>
                    <option value="gym">Gym</option>
                    <option value="promotion">Promotion</option>
                  </select>
                </p>
                <hr />
                {orgType === 'gym' && selectedAddress === '' &&
                  <>
                    <label htmlFor="postcode">Search Address</label>
                    <input
                      id="postcode"
                      type="text"
                      name="postcode"
                      placeholder="Start typing your address or postcode..."
                      value={postcode}
                      onChange={handlePostcodeChange}
                      autoComplete="off"
                    />
                    {suggestions.length > 0 && (
                      <ul className={styles.addressList}>
                        {suggestions.map((suggestion) => (
                          <li key={suggestion.Id} onClick={() => handleSuggestionsClick(suggestion)}>
                            {suggestion.Text} - {suggestion.Description}
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                }
                {orgType === 'gym' && selectedAddress !== '' &&
                  <>
                    <p>
                      <label htmlFor="address">Address</label>
                      <input id="address" type="text" name="address" value={selectedAddress.replace(/\n/g, ', ')} readOnly />
                      {showProvince ? (
                        <>
                          <label htmlFor="province">Select County</label>
                          <select id="province" name="province" value={province} onChange={(e) => setProvince(e.target.value)} required>
                            <option value="">Please select a county...</option>
                            {Object.keys(counties).map((county) => (
                              <option key={county} value={county}>{county}</option>
                            ))}
                          </select>
                        </>
                      ) : (
                        <input type="hidden" name="province" value={province} />
                      )}
                    </p>
                    <p className={styles.resetaddress} onClick={handleResetAddress}>Click here to search for another address</p>
                  </>
                }
                <hr />
                <p>
                  <label htmlFor="contactEmail">Contact Email (for public page)</label>
                  <input id="contactEmail" type="email" name="contactEmail" required />
                </p>
                <>
                  <label htmlFor="phoneNumber">Phone Number (for notifications only)</label>
                  <div className={styles.phoneInput}>
                    <select className={styles.areaCode} id="areaCode" name="areaCode" value={areaCode} onChange={(event) => setAreaCode(event.target.value)}>
                      <option value="+44">+44</option>
                    </select>
                    <input id="phoneNumber" type="text" name="phoneNumber" placeholder="Enter a contact number for your organisation..." required />
                  </div>
                </>
              </>
            }
          </>}
          <div className={styles.actions}>
            <button disabled={isSubmitting}>
              {isSubmitting && !isRequestPasswordReset ? 'Submitting...' : isLogin ? 'Login' : isResetPassword ? 'Reset Password' : 'Register'}
            </button>
            {!isResetPassword &&
              <div className={styles.bottomLinks}>
                <Link to={`${isLogin ? '/register' : '/login'}`}>
                  {isLogin ? 'Need to register?' : 'Already have an account?'}
                </Link>
                {isLogin &&
                  <Link className={styles.resetLink} to={`request-password-reset`}>
                    Reset Password
                  </Link>
                }
              </div>
            }
          </div>
        </Form>
      </div>
    </>
  );
}

export default AuthForm;