import { useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import UserCard from './UserCard';

import styles from './AdminPanel.module.css';

const AdminPanel = () => {
  const data = useLoaderData();
  const [searchData, setSearchData] = useState('');
  const [checkedFilters, setCheckedFilters] = useState({});

  const changeSearchDataHandler = (event) => {
    setSearchData(event.target.value);
  }

  const changeFilterHandler = (event) => {
    setCheckedFilters({ ...checkedFilters, [event.target.name]: event.target.checked })
  }

  return <div className={styles.admin}>
    <h1>Admin Panel</h1>
    <div className={styles.search}>
      <input id="search" type="text" name="search" value={searchData} onChange={changeSearchDataHandler} placeholder="Search for users by email, organisation name or phone number..." />
      <div className={styles.filters}>
        <input id="gymFilter" type="checkbox" name="gymFilter" checked={checkedFilters["gymFilter"] || false} onChange={changeFilterHandler} />
        <label htmlFor="gymFilter">Gyms</label>
        <input id="promotionFilter" type="checkbox" name="promotionFilter" checked={checkedFilters["promotionFilter"] || false} onChange={changeFilterHandler} />
        <label htmlFor="promotionFilter">Promotions</label>
        <input id="pendingFilter" type="checkbox" name="pendingFilter" checked={checkedFilters["pendingFilter"] || false} onChange={changeFilterHandler} />
        <label htmlFor="pendingFilter">Pending</label>
        <input id="bannedFilter" type="checkbox" name="bannedFilter" checked={checkedFilters["bannedFilter"] || false} onChange={changeFilterHandler} />
        <label htmlFor="bannedFilter">Banned</label>
      </div>
    </div>

    <h3>Total Users: {data.length - 1}</h3>

    <ul className={styles.usersList}>
      {data.map(user => (
        user.role !== 'ROLE_ADMIN' && (searchData.trim().length === 0
          || user.username.toLowerCase().includes(searchData.trim().toLowerCase())
          || user.organisation.toLowerCase().includes(searchData.trim().toLowerCase())
          || user.phone_number.includes(searchData.trim())) &&
        (!checkedFilters["gymFilter"] || user.role === 'ROLE_GYM') &&
        (!checkedFilters["promotionFilter"] || user.role === 'ROLE_PROMOTION') &&
        (!checkedFilters["pendingFilter"] || user.pending) &&
        (!checkedFilters["bannedFilter"] || user.banned) &&
        (
          <li key={user.id} className={styles.usersListItem}>
            <UserCard user={user} />
          </li>
        )
      ))}
    </ul>
  </div>
}

export default AdminPanel;