import GymContent from '../components/explore/GymContent';

const Gym = () => {
  return <GymContent />
}

export default Gym;

export const loader = async ({ params }) => {
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const accountsResponse = await fetch(authUrl + '/api/v1/accounts/id/' + params.gymId);
  const accountsResData = await accountsResponse.json();

  const fightersResponse = await fetch(authUrl + '/api/v1/fighters/gym/' + params.gymId);
  const fightersResData = await fightersResponse.json();

  return {...accountsResData, fighters: fightersResData};
}