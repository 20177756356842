import { json, redirect } from 'react-router-dom';

import ConfirmModal from '../components/ui/ConfirmModal';

const ConfirmEmailResend = () => {
  return <ConfirmModal title="Resend Email?" />
}

export default ConfirmEmailResend;

export const action = async ({ params }) => {
  let options = {
    method: 'POST'
  };
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const authResponse = await fetch(authUrl + '/api/v1/auth/resend-email/' + params.email, options);
  if (authResponse.status === 401) {
    return redirect('..');
  }
  if (!authResponse.ok) {
    throw json({ message: 'An unexpected error has occurred' }, { status: 500 });
  }
  return redirect('..');
}