import { Outlet, redirect } from 'react-router-dom';
import EventContent from '../components/explore/EventContent';
import { checkAuthLoader } from '../util/auth';

const Event = () => {
  return <>
    <Outlet />
    <EventContent />
  </>
}

export default Event;

export const loader = async ({ params }) => {
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const eventsResponse = await fetch(authUrl + '/api/v1/events/fetch/' + params.eventId);
  const eventsResData = await eventsResponse.json();

  if (eventsResponse.status === 404) {
    return redirect('/explore');
  }

  // Check if user is logged in and fetch inbox data
  let showInbox = false;
  const token = await checkAuthLoader();
  if (!(token instanceof Response)) {
    const inboxResponse = await fetch(`${authUrl}/api/v1/inbox/retrieve`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (inboxResponse.ok) {
      const inboxData = await inboxResponse.json();
      const inboxIds = inboxData.map(inbox => inbox.id);
      showInbox = inboxIds.includes(eventsResData.inbox_id);
    }
  }

  const accountsResponse = await fetch(authUrl + '/api/v1/accounts/id/' + eventsResData.promotion_id);
  const accountsResData = await accountsResponse.json();
  eventsResData.promotion = accountsResData;

  // Get available fighters
  const uniqueFighterIds = [...new Set(eventsResData.available_fighters.map(fighter => fighter.fighter_id))];
  const availableFightersResponse = await fetch(authUrl + '/api/v1/fighters/list', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(uniqueFighterIds)
  });
  const availableFightersResData = await availableFightersResponse.json();
  // Create a map of fighter_id to full fighter object for quick lookup
  const fighterMap = new Map();
  availableFightersResData.forEach(fighter => {
    fighterMap.set(fighter.id, fighter);
  });

  // Update eventsResData.available_fighters with full fighter objects
  const updatedAvailableFighters = eventsResData.available_fighters.map(originalFighter => {
    const fullFighter = fighterMap.get(originalFighter.fighter_id);
    if (!fullFighter) {
      return null;
    }
    return {
      ...fullFighter,
      sport: originalFighter.sport,
    };
  }).filter(fighter => fighter !== null);

  eventsResData.available_fighters = updatedAvailableFighters;

  // Fetch gyms
  const gymsResponse = await fetch(authUrl + '/api/v1/accounts/gyms');
  const gymsResData = await gymsResponse.json();

  // Create a map for gyms
  const gymsMap = new Map(gymsResData.map(gym => [gym.user_id, gym.organisation]));

  // Get fighter IDs from fights array
  const fightFighterIds = eventsResData.fights.flatMap(fight => [
    fight.fighter_a.fighter_id,
    fight.fighter_b.fighter_id,
  ]);

  // Get fighters data for the fights
  const fightsFightersResponse = await fetch(authUrl + '/api/v1/fighters/list', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fightFighterIds)
  });
  const fightsFightersResData = await fightsFightersResponse.json();
  const fightsFightersMap = new Map(fightsFightersResData.map(fighter => [fighter.id, fighter]));

  // Replace fighter_a and fighter_b objects with the received data
  eventsResData.fights.forEach(fight => {
    fight.fighter_a = { ...fightsFightersMap.get(fight.fighter_a.fighter_id), gym_name: gymsMap.get(fight.fighter_a.gym_id) };
    fight.fighter_b = { ...fightsFightersMap.get(fight.fighter_b.fighter_id), gym_name: gymsMap.get(fight.fighter_b.gym_id) };
  });

  return {
    ...eventsResData,
    showInbox,
  };
}