import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLoaderData, useRouteLoaderData, useNavigate, useSearchParams, Outlet } from 'react-router-dom';
import { decodeToken, tokenLoader } from '../../util/auth';
import InboxTile from './InboxTile';
import MessageDisplay from './MessageDisplay';
import NewInboxContent from './NewInboxContent';
import { useSocket } from '../../context/SocketContext';

import styles from './InboxContent.module.css';

const InboxContent = () => {
    const token = useRouteLoaderData('root');
    const loaderData = useLoaderData();
    const navigate = useNavigate();
    const [inboxData, setInboxData] = useState(() => {
        return loaderData.sort((a, b) => new Date(b.sorting_timestamp) - new Date(a.sorting_timestamp));
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedInboxId = searchParams.get('selectedInbox');
    const [selectedInbox, setSelectedInbox] = useState(selectedInboxId || null);
    const [inboxMessages, setInboxMessages] = useState(null);
    const [messageDisplayState, setMessageDisplayState] = useState({
        showParticipants: false,
        showAddParticipants: false
    });

    const [isEditing, setIsEditing] = useState(false);
    const [newInboxName, setNewInboxName] = useState('');
    const [kickingMember, setKickingMember] = useState(null);
    const [adminToggle, setAdminToggle] = useState(null);
    const [selectedNewUsers, setSelectedNewUsers] = useState([]);
    const [newUserSearchTerm, setNewUserSearchTerm] = useState('');
    const [showNewInbox, setShowNewInbox] = useState(false);
    const [users, setUsers] = useState([]);

    const { lastMessage, lastDeletedMessage, fetchUnreadCount, connectWebSocket } = useSocket();

    const initialLoadRef = useRef(true);

    let decodedToken = null;
    if (token) {
        decodedToken = decodeToken(token);
    }

    const refreshInboxData = useCallback(async (resetSelectedInbox) => {
        const latestToken = await tokenLoader();
        const authUrl = process.env.REACT_APP_AUTH_URL;
        try {
            const response = await fetch(`${authUrl}/api/v1/inbox/retrieve`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${latestToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch inbox data');
            }

            const data = await response.json();
            setInboxData(data.sort((a, b) => new Date(b.sorting_timestamp) - new Date(a.sorting_timestamp)));
            if (resetSelectedInbox) {
                setSelectedInbox(null);
                setInboxMessages(null);
                setSearchParams({});
                setShowNewInbox(false);
            }
        } catch (error) {
            console.error('Error refreshing inbox data:', error);
        }
    }, [setSearchParams]);

    const createNewInbox = useCallback(async () => {
        await refreshInboxData();
        setShowNewInbox(false);
    }, [refreshInboxData]);

    const fetchInboxMessages = useCallback(async (inboxId, limit = 25, lastMessageId = null) => {
        const latestToken = await tokenLoader();
        const authUrl = process.env.REACT_APP_AUTH_URL;
        try {
            const url = new URL(`${authUrl}/api/v1/inbox/read/${inboxId}`);
            url.searchParams.set('limit', limit);
            if (lastMessageId) {
                url.searchParams.set('lastMessageId', lastMessageId);
            }
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${latestToken}`
                }
            });
            if (response.status === 401) {
                // Token might have expired just after refresh, try one more time
                const refreshedToken = await tokenLoader();
                if (!refreshedToken) {
                    console.error('Failed to refresh token');
                    navigate('/inbox');
                    return null;
                }
                const retryResponse = await fetch(`${authUrl}/api/v1/inbox/read/${inboxId}`, {
                    headers: {
                        'Authorization': `Bearer ${refreshedToken}`
                    }
                });
                if (!retryResponse.ok) {
                    throw new Error('Failed to fetch inbox messages after token refresh');
                }
                return await retryResponse.json();
            }
            if (!response.ok) {
                throw new Error('Failed to fetch inbox messages');
            }
            const data = await response.json();
            if (!lastMessageId) {
                setInboxMessages(data);
                setMessageDisplayState({
                    showParticipants: false,
                    showAddParticipants: false
                });
                setIsEditing(false);
                setKickingMember(null);
                setAdminToggle(null);
            }
            return data;
        } catch (error) {
            console.error('Error fetching inbox messages:', error);
            return null;
        }
    }, [navigate]);

    const updateInboxData = useCallback(async (newMessage) => {
        setInboxData((prevInboxData) => {
            const updatedInboxData = prevInboxData.map((inbox) =>
                inbox.id === newMessage.inbox_id
                    ? {
                        ...inbox,
                        latest_message: newMessage,
                        sorting_timestamp: newMessage.timestamp,
                        last_read_timestamp: ((selectedInbox === newMessage.inbox_id &&
                            !messageDisplayState.showParticipants &&
                            !messageDisplayState.showAddParticipants) || newMessage.sender_id === decodedToken?.activeRoleUserId)
                            ? newMessage.timestamp
                            : inbox.last_read_timestamp
                    }
                    : inbox
            );
            return updatedInboxData.sort((a, b) => new Date(b.sorting_timestamp) - new Date(a.sorting_timestamp));
        });
    }, [selectedInbox, messageDisplayState, decodedToken?.activeRoleUserId]);

    const addMessageToState = useCallback(async (newMessage) => {
        updateInboxData(newMessage);

        if (newMessage.inbox_id === selectedInbox) {
            const messageExists = inboxMessages?.messages.some(msg => msg.id === newMessage.id);
            setInboxMessages((prevMessages) => {
                if (!prevMessages) return null;
                if (!messageExists) {
                    return {
                        ...prevMessages,
                        messages: [...prevMessages.messages, newMessage]
                    };
                }
                return prevMessages;
            });
            if (!messageExists) {
                const token = await tokenLoader();
                await fetch(`${process.env.REACT_APP_AUTH_URL}/api/v1/inbox/read/update-timestamp/${newMessage.inbox_id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    method: 'POST',
                });
            }
        } else {
            await fetchUnreadCount();
        }
    }, [selectedInbox, updateInboxData, inboxMessages?.messages, fetchUnreadCount]);

    const deleteMessageFromState = useCallback((deleteMessage) => {
        refreshInboxData();
        if (deleteMessage.inbox_id === selectedInbox) {
            setInboxMessages((prevMessages) => {
                if (!prevMessages) return null; // Add this check
                return {
                    ...prevMessages,
                    messages: prevMessages.messages.filter((message) => message.id !== deleteMessage.message_deleted)
                };
            });
        }
    }, [selectedInbox, refreshInboxData]);

    useEffect(() => {
        if (lastMessage) {
            addMessageToState(lastMessage);
        }
    }, [lastMessage, addMessageToState]);

    useEffect(() => {
        if (lastDeletedMessage) {
            deleteMessageFromState(lastDeletedMessage);
        }
    }, [lastDeletedMessage, deleteMessageFromState]);

    const handleInboxClick = useCallback(async (inboxId) => {
        setSearchParams({ selectedInbox: inboxId });
        setSelectedInbox(inboxId);
        setShowNewInbox(false);
        const fetchedData = await fetchInboxMessages(inboxId);
        if (fetchedData) {
            setInboxData((prevInboxData) => {
                return prevInboxData.map((inbox) =>
                    inbox.id === inboxId
                        ? { ...inbox, last_read_timestamp: fetchedData.last_read_timestamp }
                        : inbox
                );
            });
            // Merge fetched messages with any new messages received via WebSocket
            setInboxMessages((prevMessages) => {
                if (!prevMessages) return fetchedData;
                const existingMessageIds = new Set(prevMessages.messages.map(msg => msg.id));
                const newMessages = fetchedData.messages.filter(msg => !existingMessageIds.has(msg.id));
                return {
                    ...fetchedData,
                    messages: [...prevMessages.messages, ...newMessages]
                };
            });
            await fetchUnreadCount();
        }
    }, [setSearchParams, fetchInboxMessages, fetchUnreadCount]);

    const handleComposeClick = async () => {
        const token = await tokenLoader();
        const authUrl = process.env.REACT_APP_AUTH_URL;
        const response = await fetch(`${authUrl}/api/v1/accounts/explore`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch accounts');
        }
        const decodedToken = decodeToken(token);
        const data = await response.json();
        const filteredData = data.filter(user => user.user_id !== decodedToken.activeRoleUserId);
        setUsers(filteredData);
        setSelectedInbox(null);
        setSearchParams({});
        setInboxMessages(null);
        setShowNewInbox(true);
    };

    const handleBackClick = useCallback(() => {
        setSelectedInbox(null);
        setSearchParams({});
        setInboxMessages(null);
        setShowNewInbox(false);
    }, [setSearchParams]);

    useEffect(() => {
        if (initialLoadRef.current) {
            connectWebSocket();
            initialLoadRef.current = false;
            if (selectedInboxId) {
                handleInboxClick(selectedInboxId);
            }
        }
    }, [selectedInboxId, handleInboxClick, connectWebSocket]);

    return (
        <>
            <div className={styles.container}>
                <div className={`${styles.header} ${selectedInbox ? styles.hideBar : ''}`}>
                    <h1>Message Inbox</h1>
                    <button className={styles.newMessageButton} onClick={handleComposeClick}>
                        New Inbox
                    </button>
                </div>
                <div className={`${styles.inboxContent} ${selectedInbox || showNewInbox ? styles.showMessages : styles.hideMessages}`}>
                    {(selectedInbox || showNewInbox) && (
                        <button className={styles.backButton} onClick={handleBackClick}>
                            Back to Inbox
                        </button>
                    )}
                    <div className={styles.inboxList}>
                        {inboxData.map((inbox) => (
                            <InboxTile
                                key={inbox.id}
                                inbox={inbox}
                                userId={decodedToken?.activeRoleUserId}
                                isSelected={selectedInbox === inbox.id}
                                onSelect={handleInboxClick}
                                inboxName={inbox.inbox_name}
                            />
                        ))}
                    </div>
                    {selectedInbox && !showNewInbox && (
                        <div className={styles.messageDisplayContainer}>
                            <MessageDisplay
                                key={selectedInbox}
                                inboxMessages={inboxMessages}
                                setInboxMessages={setInboxMessages}
                                currentUser={decodedToken?.activeRoleUserId}
                                updateInboxData={updateInboxData}
                                fetchInboxMessages={fetchInboxMessages}
                                refreshInboxData={refreshInboxData}
                                messageDisplayState={messageDisplayState}
                                setMessageDisplayState={setMessageDisplayState}
                                isEditing={isEditing}
                                setIsEditing={setIsEditing}
                                newInboxName={newInboxName}
                                setNewInboxName={setNewInboxName}
                                kickingMember={kickingMember}
                                setKickingMember={setKickingMember}
                                adminToggle={adminToggle}
                                setAdminToggle={setAdminToggle}
                                selectedNewUsers={selectedNewUsers}
                                setSelectedNewUsers={setSelectedNewUsers}
                                newUserSearchTerm={newUserSearchTerm}
                                setNewUserSearchTerm={setNewUserSearchTerm}
                            />
                        </div>
                    )}
                    {!selectedInbox && showNewInbox && (
                        <div className={styles.messageDisplayContainer}>
                            <NewInboxContent users={users} createNewInbox={createNewInbox} />
                        </div>
                    )}
                </div>
            </div>
            <Outlet context={{ refreshInboxData }} />
        </>
    );
};

export default InboxContent;