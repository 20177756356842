import { checkAuthLoader } from '../util/auth';

import ActionMatchOptions from '../components/dashboard/ActionMatchOptions';

const ActionMatch = () => {
  return <ActionMatchOptions />
}

export const loader = async ({ params }) => {
  const token = await checkAuthLoader();
  if (token instanceof Response) {
    return token;
  }
  const options = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const authUrl = process.env.REACT_APP_AUTH_URL
  const eventsResponse = await fetch(authUrl + '/api/v1/events/pending/' + params.fightId, options);
  const eventsResData = await eventsResponse.json();

  return eventsResData;
}

export default ActionMatch;