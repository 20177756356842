import { useState, useEffect } from 'react';
import { useParams, useLoaderData } from 'react-router-dom';

import OptionCard from './OptionCard';

import styles from './DirectoryTypesContent.module.css';
import typesList from './types.js';

const DirectoryTypesContent = () => {
  const counts = useLoaderData();
  const { region } = useParams();
  const [searchData, setSearchData] = useState('');

  const changeSearchDataHandler = (event) => {
    setSearchData(event.target.value);
  }

  const filteredTypes = typesList.filter(type => {
    const trimmedSearchData = searchData.trim().toLowerCase();
    const lowerCaseType = type.toLowerCase();
    return trimmedSearchData === '' || lowerCaseType.includes(trimmedSearchData);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const prettyRegion = (region) => {
    const lowerCaseWords = ['of', 'and', 'in', 'the', 'to']; // Add more words as needed

    return region
        .split('-')
        .map(part => {
            return lowerCaseWords.includes(part.toLowerCase())
                ? part.toLowerCase()
                : part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
        })
        .join(' ');
  }

  return (
    <div className={styles.directory}>
      <h1>Region: {prettyRegion(region)} - Choose a Service</h1>
      <div className={styles.search}>
        <input
          id="search"
          type="text"
          name="search"
          value={searchData}
          onChange={changeSearchDataHandler}
          autoComplete='new-password'
          placeholder="Search for a service..."
        />
      </div>
      <ul className={styles.typeList}>
        {filteredTypes.map(type => (
          <li key={type} className={styles.typeItem}>
            <OptionCard title={type} link='type' count={counts[type]} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DirectoryTypesContent;
